import { Add, KeyboardArrowDown } from '@mui/icons-material';
import { EditType } from '@on3/api';
import clsx from 'clsx';
import { type TypeVitalEdit } from 'components/Elite/Profile/Edits/Vitals';
import { Vital } from 'components/Elite/Profile/Edits/Vitals/Vital';
import { useEffect, useState } from 'react';
import { measurementTypes } from 'store/data/vitals';

import styles from './EditsVitalGroup.module.scss';

interface IVitalGroupProps {
  vitalKey: string;
  data: (TypeVitalEdit & { index: number })[];
  handleAddNew: (measurementTypeKey: number) => void;
}

export const VitalGroup = ({
  vitalKey,
  data,
  handleAddNew,
}: IVitalGroupProps) => {
  const [expanded, setExpanded] = useState(false);

  const measurementTypeName =
    measurementTypes.find((mt) => mt.key === Number(vitalKey))?.type ||
    'Unknown';

  useEffect(() => {
    if (data.some((v) => v.editType === EditType.Add)) {
      setExpanded(true);
    }
  }, [data]);

  return (
    <section className={styles.block}>
      <h3>
        <button
          aria-controls={`panel-${vitalKey}`}
          aria-expanded={expanded}
          className={styles.accordion}
          onClick={() => setExpanded((prev) => !prev)}
          type="button"
        >
          {measurementTypeName}
          <span className={styles.count}>
            {data.length > 0 ? ` (${data.length})` : ''}
          </span>
          <KeyboardArrowDown className={styles.arrow} />
        </button>
      </h3>
      <ol
        className={clsx(styles.vitalList, {
          [styles.collapsed]: !expanded,
        })}
        id={`panel-${vitalKey}`}
      >
        <li>
          <button
            className={styles.addBtn}
            onClick={() => handleAddNew(Number(vitalKey))}
            type="button"
          >
            <Add />
            <span>New {measurementTypeName} Measurement</span>
          </button>
        </li>
        {data.map((v) => (
          <li className={styles.vital} key={v.key}>
            <Vital index={v.index} vital={v} />
          </li>
        ))}
      </ol>
    </section>
  );
};
