import {
  type AdminPersonProfileRes,
  EditType,
  type PlayerProfileResponse,
  v1PeopleProfileEditsCreate,
  type VitalsProfileEdit,
} from '@on3/api';
import { Form } from '@on3/ui-lib/components/Form';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { Select } from '@on3/ui-lib/components/Select/Select';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { filterNumber } from '@on3/ui-lib/utils/filters';
import { yup } from '@on3/ui-lib/utils/yup';
import { VitalsHeight } from 'components/Elite/Profile/Edits/Vitals/Height';
import { useState } from 'react';
import { measurementTypes } from 'store/data/vitals';

import styles from './VitalModal.module.scss';

interface IVitalModalProps {
  player: PlayerProfileResponse | AdminPersonProfileRes;
  onComplete: (vital: VitalsProfileEdit) => void;
  measurementTypeKey?: number;
}

const schema = yup.object().shape({
  measurementTypeKey: yup.number().required('Measurement type is required'),
  dateOccurred: yup.string(),
});

export const VitalModal = ({
  player,
  onComplete,
  measurementTypeKey,
}: IVitalModalProps) => {
  const playerKey =
    (player as PlayerProfileResponse).key ??
    (player as AdminPersonProfileRes).person?.key;

  const { notify } = useToast();

  const [values, setValues] = useState<VitalsProfileEdit>({
    $type: 'vitals',
    editType: EditType.Add,
    measurementTypeKey: measurementTypeKey ?? 0,
    value: '',
    dateOccurred: new Date().toISOString().split('T')[0],
  });

  const handleUpdate = (
    field: keyof VitalsProfileEdit,
    value: string | number,
  ) => {
    setValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleValueUpdate = (v: string) => {
    setValues((prev) => ({
      ...prev,
      value: v,
    }));
  };

  const handleSubmit = async (data: VitalsProfileEdit) => {
    try {
      const { reviewItem } = await v1PeopleProfileEditsCreate(
        externalApi,
        playerKey,
        {
          edit: data,
        },
      );

      notify('Your measurement has been saved!', {
        type: 'success',
      });

      onComplete(reviewItem.newValue as VitalsProfileEdit);
    } catch (error) {
      notify('Unable to save your measurement. Please try again!', {
        type: 'error',
      });
    }
  };

  const measurementTypeOptions = measurementTypes.map((type) => ({
    label: type.type,
    value: String(type.key),
  }));

  const canSubmit =
    !!values.measurementTypeKey && !!values.value && !!values.dateOccurred;

  return (
    <Form
      className={styles.block}
      onSubmit={handleSubmit}
      schema={schema}
      values={values}
    >
      {({ errors, processing }) => (
        <>
          <header>
            <h2>Add a measurement</h2>
            <div className={styles.note}>
              <p>Add your height, weight, 40, bench press, and more.</p>
            </div>
          </header>
          <div>
            <Select
              disabled={processing}
              error={errors.measurementTypeKey}
              label="Measurement Type"
              name="vitals-measurementTypeKey"
              onChange={(e) =>
                handleUpdate('measurementTypeKey', Number(e.target.value))
              }
              options={measurementTypeOptions}
              placeholder="Select measurement type"
              required
              value={String(values.measurementTypeKey)}
            />
          </div>
          <div>
            {values.measurementTypeKey === 1 ? (
              <VitalsHeight
                handleUpdate={handleValueUpdate}
                value={values.value}
              />
            ) : (
              <Input
                disabled={processing || !values.measurementTypeKey}
                error={errors.value}
                inputMode="decimal"
                label="Result"
                name="vitals-value"
                onChange={(e) =>
                  handleUpdate('value', filterNumber(e.target.value))
                }
                required
                suffix={
                  <span className={styles.units}>
                    {
                      measurementTypes.find(
                        (type) => type.key === values.measurementTypeKey,
                      )?.units
                    }
                  </span>
                }
                value={values.value ?? ''}
              />
            )}
          </div>
          <div>
            <Input
              defaultValue={values.dateOccurred?.split('T')[0] ?? ''}
              disabled={processing}
              error={errors.dateOccurred}
              label="Date"
              name="vitals-date"
              onChange={(e) =>
                handleUpdate(
                  'dateOccurred',
                  e.target.value
                    ? new Date(e.target.value).toISOString().split('T')[0]
                    : '',
                )
              }
              required
              type="date"
            />
          </div>
          <button
            className={styles.saveBtn}
            disabled={processing || !canSubmit}
            type="submit"
          >
            Save
          </button>
        </>
      )}
    </Form>
  );
};
