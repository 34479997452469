import clsx from 'clsx';
import React, { type FC, forwardRef } from 'react';
import { type FieldError } from 'react-hook-form';

import styles from './Select.module.scss';

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  options: SelectOption[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  srLabel?: boolean;
  error?: FieldError | string;
  placeholder?: string;
  value?: string | null;
  [key: string]: any;
  disabled?: boolean;
}

const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      srLabel = false,
      error,
      placeholder = '',
      value,
      options = [],
      onChange,
      disabled,
      required,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={styles.base} data-ui="select">
        <label>
          <span
            className={clsx({
              'sr-only': srLabel,
              [styles.required]: required,
            })}
            data-ui="select-label"
          >
            {label}
          </span>
          <select
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            value={value || ''}
            {...props}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((option: SelectOption) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

Select.displayName = 'Select';

export { Select };
