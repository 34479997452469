import { Input } from '@on3/ui-lib/components/Input/Input';
import { isDiff } from '@on3/ui-lib/utils/compare';
import { filterNumber } from '@on3/ui-lib/utils/filters';
import {
  formatHeightToNumber,
  formatHeightToString,
} from '@on3/ui-lib/utils/vitals';
import clsx from 'clsx';
import { ProfileVitalsContext } from 'components/Elite/Profile/Edits/Vitals';
import { useContext, useEffect, useState } from 'react';

import styles from './VitalsHeight.module.scss';

interface IVitalsHeightProps {
  handleUpdate: (value: string) => void;
  value?: string | null;
  srLabel?: boolean;
}

export const VitalsHeight = ({
  value,
  handleUpdate,
  srLabel = false,
}: IVitalsHeightProps) => {
  const { processing } = useContext(ProfileVitalsContext);

  const [state, setState] = useState({
    feet: formatHeightToString(value, 'feet'),
    inches: formatHeightToString(value, 'inches'),
  });

  useEffect(() => {
    const newHeight = formatHeightToNumber(
      `${state.feet || 0}-${state.inches || 0}`,
    );

    if (isDiff(value, String(newHeight))) {
      handleUpdate(String(newHeight));
    }
  }, [handleUpdate, state.feet, state.inches, value]);

  return (
    <div className={styles.block}>
      <span
        className={clsx(styles.label, {
          [styles.srOnly]: srLabel,
        })}
      >
        Units
      </span>
      <div className={styles.height}>
        <Input
          disabled={processing}
          label="Feet"
          name="feet"
          onChange={(e) =>
            setState((curr) => ({
              ...curr,
              feet: filterNumber(e.target.value),
            }))
          }
          srLabel
          step="1"
          suffix="ft"
          type="number"
          value={state.feet}
        />
        <Input
          disabled={processing}
          label="Inches"
          name="inches"
          onChange={(e) =>
            setState((curr) => ({
              ...curr,
              inches: filterNumber(e.target.value),
            }))
          }
          srLabel
          step="0.5"
          suffix="in"
          type="number"
          value={state.inches}
        />
      </div>
    </div>
  );
};
