import { CalendarToday, Close } from '@mui/icons-material';
import { EditType } from '@on3/api';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { filterNumber } from '@on3/ui-lib/utils/filters';
import {
  ProfileVitalsContext,
  type TypeVitalEdit,
} from 'components/Elite/Profile/Edits/Vitals';
import { VitalsHeight } from 'components/Elite/Profile/Edits/Vitals/Height';
import { useCallback, useContext } from 'react';
import { measurementTypes } from 'store/data/vitals';

import styles from './EditsVital.module.scss';

interface IVitalProps {
  index: number;
  vital: TypeVitalEdit;
}

export const Vital = ({ index, vital }: IVitalProps) => {
  const { processing, errors, handleUpdate, handleRemove } =
    useContext(ProfileVitalsContext);

  const handleValueUpdate = useCallback(
    (v: string) => handleUpdate(index, 'value', v),
    [handleUpdate, index],
  );

  return (
    <div className={styles.vital}>
      <div className={styles.date}>
        <Input
          defaultValue={vital.dateOccurred?.split('T')[0] ?? ''}
          disabled={processing}
          error={errors[`[${index}].dateOccurred`]}
          label="Date"
          name={`vitals-${index}-date`}
          onChange={(e) =>
            handleUpdate(
              index,
              'dateOccurred',
              e.target.value
                ? new Date(e.target.value).toISOString().split('T')[0]
                : '',
            )
          }
          prefix={
            <div className={styles.calendar}>
              <CalendarToday className={styles.icon} />
              <span>{vital.dateOccurred?.split('T')[0]}</span>
            </div>
          }
          srLabel
          type="date"
        />
      </div>
      <div className={styles.results}>
        {vital.measurementTypeKey === 1 ? (
          <VitalsHeight
            handleUpdate={handleValueUpdate}
            srLabel
            value={vital.value}
          />
        ) : (
          <Input
            disabled={processing || !vital.measurementTypeKey}
            error={errors[`[${index}].value`]}
            inputMode="decimal"
            label="Result"
            name={`vitals-${index}-value`}
            onChange={(e) =>
              handleUpdate(index, 'value', filterNumber(e.target.value))
            }
            srLabel
            suffix={
              <span className={styles.units}>
                {
                  measurementTypes.find(
                    (type) => type.key === vital.measurementTypeKey,
                  )?.units
                }
              </span>
            }
            value={vital.value ?? ''}
          />
        )}
      </div>
      {vital.editType === EditType.Add && (
        <div className={styles.action}>
          <button
            className={styles.removeBtn}
            disabled={processing}
            name={`remove-vital-${index}`}
            onClick={() => handleRemove(index)}
            type="button"
          >
            <span className="sr-only">Remove</span>
            <Close />
          </button>
        </div>
      )}
    </div>
  );
};
