import { type ComponentProps } from 'react';

import { ContactModal } from './Contact';
import { VisitsModal } from './Visits';
import { VitalModal } from './Vital';

export type PlayerModalType =
  | 'player.contact'
  | 'player.visits'
  | 'player.vital';

export interface PlayerModalPropsMap {
  'player.contact': ComponentProps<typeof ContactModal>;
  'player.visits': ComponentProps<typeof VisitsModal>;
  'player.vital': ComponentProps<typeof VitalModal>;
}

export const playerModals = {
  'player.contact': ContactModal,
  'player.visits': VisitsModal,
  'player.vital': VitalModal,
};
