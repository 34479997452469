/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { handleKeyDown } from '@on3/ui-lib/utils/accessibility/keyDown';
import clsx from 'clsx';
import React, { type FC, forwardRef, useState } from 'react';
import { type FieldError } from 'react-hook-form';

import { Check } from '../Svg/Check';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  id: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  error?: FieldError | string;
  srLabel?: boolean;
}

const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, label, value, onChange, disabled, error, srLabel = false, ...props },
    ref,
  ) => {
    const [checked, setChecked] = useState<boolean>(value);

    // Handle the input change
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const inputVal = event.target.checked;

      setChecked(inputVal);
      onChange(inputVal);
    }

    return (
      <div className={styles.base} data-ui="checkbox">
        <label htmlFor={id}>
          <input
            checked={checked}
            disabled={disabled}
            hidden
            id={id}
            onChange={handleChange}
            ref={ref}
            type="checkbox"
            {...props}
          />
          <div
            aria-checked={checked}
            aria-disabled={disabled}
            aria-label={label}
            className={styles.checkbox}
            onKeyDown={(e) =>
              handleKeyDown(e, () =>
                handleChange({
                  target: { checked: !checked },
                } as React.ChangeEvent<HTMLInputElement>),
              )
            }
            role="checkbox"
            tabIndex={disabled ? -1 : 0}
          >
            <Check height="1em" width="1em" />
          </div>
          <span
            className={clsx({
              'sr-only': srLabel,
            })}
            data-ui="checkbox-label"
          >
            {label}
          </span>
        </label>
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
