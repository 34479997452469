import { KeyboardArrowDown } from '@mui/icons-material';
import { formatHeightToString } from '@on3/ui-lib/utils/vitals';
import { useState } from 'react';
import { measurementTypes } from 'store/data/vitals';

import styles from './EditsVitalsLatest.module.scss';

interface ILatestVitalsProps {
  data: Record<string, any>;
  handleAddNew: (measurementTypeKey: number) => void;
}

export const LatestVitals = ({ data, handleAddNew }: ILatestVitalsProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <section className={styles.block}>
      <h2>
        <button
          aria-controls={`panel-latest-vitals`}
          aria-expanded={expanded}
          className={styles.accordion}
          onClick={() => setExpanded((prev) => !prev)}
          type="button"
        >
          Latest measurements
          <KeyboardArrowDown className={styles.arrow} />
        </button>
      </h2>
      {expanded && (
        <div className={styles.panel} id="panel-latest-vitals">
          {Object.entries(data).map(([key, vitals]) => {
            const measurementType = measurementTypes.find(
              (mt) => mt.key === Number(key),
            );

            if (!measurementType) return null;

            return (
              <button
                className={styles.latestItem}
                key={key}
                onClick={() => handleAddNew(Number(key))}
                type="button"
              >
                <dl>
                  <dt>
                    {measurementTypes.find((mt) => mt.key === Number(key))
                      ?.type ?? ''}
                  </dt>
                  <dd>
                    <span>
                      {key === '1'
                        ? formatHeightToString(vitals[0].value)
                        : vitals[0].value || '-'}
                    </span>
                    <span className={styles.unit}>
                      {key === '1' ? 'ft-in' : `${measurementType.units}`}
                    </span>
                  </dd>
                </dl>
              </button>
            );
          })}
        </div>
      )}
    </section>
  );
};
