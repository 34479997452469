import { Add } from '@mui/icons-material';
import {
  EditType,
  type PublicMeasurementPersonResponseRecord,
  type VitalsProfileEdit,
} from '@on3/api';
import { Form } from '@on3/ui-lib/components/Form';
import { externalApi, useToast } from '@on3/ui-lib/index';
import { generateKey } from '@on3/ui-lib/src/utils/generateKey';
import { isDiff } from '@on3/ui-lib/utils/compare';
import { updatePath } from '@on3/ui-lib/utils/updatePath';
import { yup } from '@on3/ui-lib/utils/yup';
import { LatestVitals } from 'components/Elite/Profile/Edits/Vitals/Latest';
import { VitalGroup } from 'components/Elite/Profile/Edits/Vitals/VitalGroup';
import { useModal } from 'components/Modals';
import { usePlayerProfile } from 'contexts/PlayerProfile/PlayerProvider';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { measurementTypes } from 'store/data/vitals';
import {
  findEditByKey,
  getEditOrDefault,
  getProfileUpdates,
  getUpdatedEdits,
  type ProfileEditReviewItem,
} from 'utils/player/admin';

import styles from './EditsVitals.module.scss';

export interface IProfileVitalsEdits {
  vitals: ProfileEditReviewItem<VitalsProfileEdit>[];
}

interface IProfileVitalsProps {
  vitals: PublicMeasurementPersonResponseRecord | null;
  pendingEdits: IProfileVitalsEdits;
}

export type TypeVitalEdit = Omit<VitalsProfileEdit, 'key'> & { key?: any };

const newVital = {
  $type: 'vitals',
  editType: EditType.Add,
  measurementTypeKey: 0,
  value: '',
  dateOccurred: new Date().toISOString(),
} as TypeVitalEdit;

const schema = yup.array().of(
  yup.object().shape({
    key: yup
      .mixed()
      .transform((v) => Number(v) || null)
      .nullable(),
    measurementTypeKey: yup.number().required('Measurement type is required'),
    dateOccurred: yup.string(),
  }),
);

export const ProfileVitalsContext = createContext<{
  processing: boolean;
  errors: Record<string, any>;
  handleUpdate: (
    index: number,
    key: keyof TypeVitalEdit,
    value: string | number,
  ) => void;
  handleRemove: (index: number) => void;
}>({
  processing: false,
  errors: {},
  handleUpdate: () => {},
  handleRemove: () => {},
});

export const ProfileVitals = ({
  vitals,
  pendingEdits,
}: IProfileVitalsProps) => {
  const {
    state: { player },
  } = usePlayerProfile();
  const { notify } = useToast();
  const { showModal, closeModal } = useModal();
  const [resyncKey, setResyncKey] = useState(generateKey());
  const addRef = useRef<HTMLButtonElement>(null);

  const [edits, setEdits] = useState(pendingEdits);

  const initialVitals = useMemo(
    () =>
      (vitals?.playerMeasurements ?? [])?.map((measurement) => ({
        $type: 'vitals',
        editType: EditType.Update,
        key: getEditOrDefault(
          findEditByKey(edits.vitals, 'key', measurement.key)?.newValue?.key,
          measurement.key,
        ),
        measurementTypeKey: measurement.measurementTypeKey,
        eventKey: measurement.eventKey,
        value: getEditOrDefault(
          findEditByKey(edits.vitals, 'key', measurement.key)?.newValue?.value,
          String(measurement.value ?? ''),
        ),
        dateOccurred: getEditOrDefault(
          findEditByKey(
            edits.vitals,
            'key',
            measurement.key,
          )?.newValue?.dateOccurred?.split('T')[0],
          new Date((measurement.dateOccurred || measurement.dateAdded) * 1000)
            .toISOString()
            .split('T')[0] || '',
        ),
      })) as TypeVitalEdit[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edits, vitals, resyncKey],
  );

  const defaultVitals = [] as TypeVitalEdit[];
  const coreVitals = [1, 2, 6, 9, 16];
  const currentVitals = initialVitals.map((v) => v.measurementTypeKey);
  const initialCoreVitals = coreVitals.filter(
    (v) => !currentVitals.includes(v),
  );

  initialCoreVitals.forEach((key) => {
    defaultVitals.push({
      ...newVital,
      key: generateKey(),
      measurementTypeKey: key,
      editType: EditType.Add,
    });
  });

  const [values, setValues] = useState<TypeVitalEdit[]>([
    ...defaultVitals,
    ...initialVitals,
  ]);

  const vitalsData = useMemo(
    () =>
      values.reduce(
        (acc, curr, currentIndex) => {
          acc[curr.measurementTypeKey] ??= [] as (TypeVitalEdit & {
            index: number;
          })[];
          acc[curr.measurementTypeKey].push({ ...curr, index: currentIndex });

          return acc;
        },
        {} as Record<number, (TypeVitalEdit & { index: number })[]>,
      ),
    [values],
  );

  const handleSubmit = useCallback(
    async (data: TypeVitalEdit[]) => {
      try {
        const filtered = data.filter((v) => !!v.value);
        const updated = getProfileUpdates(
          externalApi,
          { vitals: filtered },
          { vitals: initialVitals },
          edits,
          player.key,
          ['vitals'],
          {
            vitals: 'key',
          },
        );

        const responses = await Promise.allSettled(updated.map((fn) => fn()));

        responses.forEach((r) => {
          if (r.status === 'fulfilled') {
            setEdits((curr) =>
              getUpdatedEdits(curr, 'vitals', r.value?.reviewItem),
            );

            setValues((curr) =>
              curr.map((v) => ({ ...v, editType: EditType.Update })),
            );
          }
        });

        if (responses.every((r) => r.status === 'rejected')) {
          notify('Failed to update vitals. Please try again.', {
            type: 'error',
          });

          return;
        }

        if (responses.some((r) => r.status === 'rejected')) {
          notify('Some vitals failed to update.', { type: 'warning' });

          return;
        }

        notify('Vitals submitted successfully.', { type: 'success' });

        setResyncKey(generateKey());
      } catch (error) {
        notify('Failed to save vitals. Please try again.', {
          type: 'error',
        });
      }
    },
    [edits, initialVitals, notify, player.key],
  );

  const handleUpdate = useCallback(
    (index: number, key: keyof TypeVitalEdit, value: string | number) => {
      setValues((prev) => updatePath(prev, `${index}.${key}`, value));
    },
    [],
  );

  const addNewMeasurement = (mtk: number) => {
    showModal('player.vital', {
      player,
      measurementTypeKey: mtk,
      onComplete: (v: VitalsProfileEdit) => {
        handleAddVital({
          ...v,
          editType: EditType.Update,
        });
        closeModal();
      },
    });
  };

  const handleAddVital = (v: TypeVitalEdit) =>
    setValues((prev) => [v, ...prev]);

  const handleRemove = (index: number) => {
    setValues((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <section className={styles.block}>
      <header className={styles.header}>
        <h1>Measurements & Testing</h1>
        <button
          className={styles.addBtn}
          onClick={() => addNewMeasurement(0)}
          ref={addRef}
          type="button"
        >
          <Add />
          Add measurement
        </button>
      </header>
      <Form
        className={styles.form}
        onSubmit={handleSubmit}
        schema={schema}
        values={values}
      >
        {({ processing, errors }) => (
          <ProfileVitalsContext.Provider
            value={{
              processing,
              errors,
              handleUpdate,
              handleRemove,
            }}
          >
            <LatestVitals data={vitalsData} handleAddNew={addNewMeasurement} />
            <section className={styles.section}>
              <h2>My measurements</h2>
              <div className={styles.vitals}>
                {Object.entries(vitalsData).map(([key, data]) => (
                  <VitalGroup
                    data={data}
                    handleAddNew={addNewMeasurement}
                    key={key}
                    vitalKey={key}
                  />
                ))}
                {measurementTypes
                  .filter((mt) => !vitalsData[mt.key])
                  .map((mt) => (
                    <VitalGroup
                      data={[] as any}
                      handleAddNew={addNewMeasurement}
                      key={mt.key}
                      vitalKey={String(mt.key)}
                    />
                  ))}
              </div>
            </section>
            <button
              disabled={
                processing ||
                !isDiff([...defaultVitals, ...initialVitals], values)
              }
              type="submit"
            >
              Save
            </button>
          </ProfileVitalsContext.Provider>
        )}
      </Form>
    </section>
  );
};
