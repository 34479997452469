import {
  type ApiProfileEditReviewItemRes,
  type CreateProfileEditReqBody,
  EditType,
  type ProfileEditReviewItemRes,
  type UpdateProfileEditReqBody,
  v1PeopleProfileEditsCreate,
  v1ProfileEditsUpdate,
} from '@on3/api';
import { externalApi } from '@on3/ui-lib/index';
import { isDiff } from '@on3/ui-lib/utils/compare';
import { type AxiosInstance } from 'axios';

export type ProfileEditReq =
  | CreateProfileEditReqBody['edit']
  | UpdateProfileEditReqBody['edit'];

// Type for an edit item with the API response fields
export type ProfileEditReviewItem<T = ProfileEditReq> =
  ProfileEditReviewItemRes & {
    newValue?: T;
    oldValue?: T | null;
  };

// Generic type for an edit object with mixed single and array fields
export type MixedEditsObject<TKey extends string = string> = Record<
  TKey,
  ProfileEditReviewItem | ProfileEditReviewItem[]
>;

// Generic type for the request data (raw values, not wrapped in ProfileEditReviewItem)
export type MixedRequestObject<TKey extends string = string> = Record<
  TKey,
  ProfileEditReq | ProfileEditReq[]
>;

// Type for the API update function
export type UpdateFn = () => Promise<ApiProfileEditReviewItemRes>;

/**
 * Find the latest player edit for a given predicate
 *
 * @param edits
 * @param predicate
 * @returns the player edit or null
 */
const findPlayerEdit = (
  edits: ApiProfileEditReviewItemRes[],
  predicate: (v?: ProfileEditReq) => boolean,
) =>
  edits?.find(({ reviewItem }) => predicate(reviewItem.newValue))?.reviewItem ??
  null;

/**
 * Find all player edits that match the predicate
 *
 * @param edits
 * @param predicate
 * @returns the player edits or null
 */
const findPlayerEdits = (
  edits: ApiProfileEditReviewItemRes[],
  predicate: (v?: ProfileEditReq) => boolean,
) =>
  edits
    ?.filter(({ reviewItem }) => predicate(reviewItem.newValue))
    ?.map((ri) => ri.reviewItem) ?? null;

/**
 * Find a specific edit by key
 *
 * @note key is a unique identifier for a specific ProfileEditReq type
 */
const findEditByKey = <T>(
  edits: { newValue?: T }[],
  key: keyof T,
  value: any,
) => edits.find((e) => e.newValue && e.newValue?.[key] === value) ?? null;

/**
 *  Get the value from a player edit or use the default value
 *
 * @param editValue
 * @param defaultValue
 * @returns the value from the edit or the default value
 */
const getEditOrDefault = (editValue: any, defaultValue: any) =>
  editValue ?? defaultValue;

/**
 *  Get the edit type for a player edit request
 *
 * @param isUpdate
 * @returns EditType
 */
const getEditType = (isUpdate: boolean) =>
  isUpdate ? EditType.Update : EditType.Add;

/**
 * Utility to get the profile edit fn based on existing or new edit
 */
export const createUpdateFn = (
  api: AxiosInstance,
  { $type, ...edit }: ProfileEditReq,
  playerKey: number,
  existingKey?: number,
): UpdateFn => {
  return existingKey
    ? () =>
        v1ProfileEditsUpdate(api, existingKey, {
          edit: { $type, ...edit } as ProfileEditReq,
        })
    : () =>
        v1PeopleProfileEditsCreate(api, playerKey, {
          edit: { $type, ...edit } as ProfileEditReq,
        });
};

/**
 * Utility to process a single edit (compares and generates update function)
 */
const processEdit = (
  api: AxiosInstance = externalApi,
  newValue: ProfileEditReq,
  initialValue: ProfileEditReq | null,
  existingEdit: ProfileEditReviewItem | null,
  playerKey: number,
): UpdateFn | null => {
  if (isDiff(initialValue, newValue)) {
    return createUpdateFn(api, newValue, playerKey, existingEdit?.key);
  }

  return null;
};

/**
 * Main utility to generate updates for a mixed edit object
 *
 * @note TRequest and TEdits must be compatible types with the same keys
 * @note UKey is the unique key to identify an edit (default is '$type')
 */
const getProfileUpdates = <
  TKey extends string,
  TRequest extends MixedRequestObject<TKey>,
  TEdits extends MixedEditsObject<TKey>,
  UKey extends keyof ProfileEditReq,
>(
  api: AxiosInstance,
  data: TRequest,
  initialValues: TRequest,
  edits: TEdits,
  playerKey: number,
  arrayFields?: TKey[],
  uniqueKeys?: Partial<Record<TKey, UnionKeys<ProfileEditReq>>>, // Unique key to the edit object type
): UpdateFn[] => {
  const updates: UpdateFn[] = [];

  Object.entries(data).forEach(([key, newValue]) => {
    const k = key as TKey;
    const initial = initialValues[k];
    const existing = edits[k];

    if (arrayFields?.includes(k)) {
      const newArray = (newValue || []) as ProfileEditReq[];
      const initialArray = (initial || []) as ProfileEditReq[];
      const existingArray = (existing || []) as ProfileEditReviewItem[];

      newArray.forEach((newEdit) => {
        const uniqueKey = uniqueKeys?.[k] ?? '$type';
        const uniqueValue = newEdit[uniqueKey as UKey];
        const initialEdit =
          initialArray.find((i) => i?.[uniqueKey as UKey] === uniqueValue) ??
          null;
        const existingEdit =
          existingArray.find(
            (e) => e.newValue?.[uniqueKey as UKey] === uniqueValue,
          ) ?? null;

        const update = processEdit(
          api,
          newEdit,
          initialEdit,
          existingEdit,
          playerKey,
        );

        if (update) updates.push(update);
      });
    } else {
      const update = processEdit(
        api,
        newValue as ProfileEditReq,
        initial as ProfileEditReq,
        existing as ProfileEditReviewItem,
        playerKey,
      );

      if (update) updates.push(update);
    }
  });

  return updates;
};

/**
 * Utility to update the player edits object
 *
 * @param edits
 * @param editKey
 * @param editItem
 * @returns the updated edits object
 */
const getUpdatedEdits = <E>(
  edits: E,
  editKey: keyof E,
  editItem: ProfileEditReviewItem,
) => {
  const updatedEdits = { ...edits };
  const existing = updatedEdits[editKey];

  if (Array.isArray(existing)) {
    const index = editItem.key
      ? existing.findIndex((e) => e.key === editItem.key)
      : -1;

    if (index !== -1) {
      existing[index] = editItem;
    } else {
      existing.push(editItem);
    }

    updatedEdits[editKey] = existing;
  } else {
    updatedEdits[editKey] = editItem as E[keyof E];
  }

  return updatedEdits;
};

export {
  findEditByKey,
  findPlayerEdit,
  findPlayerEdits,
  getEditOrDefault,
  getEditType,
  getProfileUpdates,
  getUpdatedEdits,
};
