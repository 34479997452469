import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AcademicInfoProfileEdit {
  $type: 'academicInfo';
  /**
   * @format int32
   * @min 1
   * @max 36
   */
  act?: number | null;
  editType: EditType;
  file?: FileRes;
  /**
   * @format double
   * @min 0
   * @max 5
   */
  gpa?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  intendedMajor?: string | null;
  /**
   * @format int32
   * @min 400
   * @max 1600
   */
  sat?: number | null;
}

export interface AdminCollectedProfileInfoRes {
  cell?: string | null;
  /** @format date-time */
  created: string;
  email?: string;
  firstName?: string;
  /** @format int32 */
  highSchoolClassYear: number;
  highSchoolFound: boolean;
  highSchoolOrganization?: SlimOrganizationRes;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  highSchoolSearch?: string | null;
  instagram?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  /** @format uuid */
  nonce: string;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey?: number | null;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
  status: PersonCreationStatus;
  /** @format date-time */
  updated?: string | null;
  user?: SimpleUserResponse;
  /** @format int64 */
  userKey?: number | null;
}

export interface AdminCollectedProfileInfoResPagedData {
  list?: AdminCollectedProfileInfoRes[];
  pagination?: Pagination;
}

export interface AgencyResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgentPersonResponse {
  agency?: AgencyResponse;
  /** @format int64 */
  key: number;
  name?: string;
}

export enum AnnouncementImageGraphicType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  TopSchools = 'TopSchools',
  DecisionDate = 'DecisionDate',
  OfficialVisit = 'OfficialVisit',
  Offers = 'Offers',
}

export enum AnnouncementImageStatus {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
}

export interface ApiClaimProfileInfoReq {
  accessInfo?:
    | ApiProcessPianoConversionForProfileInfoReq
    | ApiProcessUserAccessForProfileInfoReq
    | null;
  /** @format int64 */
  personKey: number;
}

export interface ApiCollectedProfileInfoRes {
  collectedProfileInfo: CollectedProfileInfoRes;
  highSchoolOrganization?: SlimOrganizationRes;
}

export type ApiProcessAccessForProfileInfoReq =
  BaseApiProcessAccessForProfileInfoReq &
    (
      | BaseApiProcessAccessForProfileInfoReqTypeMapping<
          'pianoConversion',
          ApiProcessPianoConversionForProfileInfoReq
        >
      | BaseApiProcessAccessForProfileInfoReqTypeMapping<
          'userAccess',
          ApiProcessUserAccessForProfileInfoReq
        >
    );

export type ApiProcessPianoConversionForProfileInfoReq = UtilRequiredKeys<
  BaseApiProcessAccessForProfileInfoReq,
  '$type'
> & {
  $type: 'pianoConversion';
  /**
   * The conversion ID from "checkoutComplete" (https://docs.piano.io/callbacks/#completeevent) when status is
   * `PersonCreationStatus.Paid`.
   */
  conversionId: string;
  /**
   * The Piano user ID from "checkoutComplete" (https://docs.piano.io/callbacks/#completeevent) when status is
   * `PersonCreationStatus.Paid`.
   */
  userId: string;
};

export type ApiProcessUserAccessForProfileInfoReq = UtilRequiredKeys<
  BaseApiProcessAccessForProfileInfoReq,
  '$type'
> & {
  $type: 'userAccess';
  /** @format int64 */
  userAccessKey: number;
};

export interface ApiProfileAccountSummaryRes {
  profileAccountSummary: ProfileAccountSummaryRes;
}

export interface ApiProfileAccountSummaryResApiRelatedPersonAccountsResRelatedPagedData {
  list?: ApiProfileAccountSummaryRes[];
  pagination?: Pagination;
  relatedModel?: ApiRelatedPersonAccountsRes;
}

export interface ApiProfileAccountSummaryResApiRelatedUserAccountsResRelatedPagedData {
  list?: ApiProfileAccountSummaryRes[];
  pagination?: Pagination;
  relatedModel?: ApiRelatedUserAccountsRes;
}

export interface ApiProfileEditCommentRes {
  comment: ProfileEditCommentRes;
}

export interface ApiProfileEditCommentResApiProfileEditReviewItemResRelatedPagedData {
  list?: ApiProfileEditCommentRes[];
  pagination?: Pagination;
  relatedModel?: ApiProfileEditReviewItemRes;
}

export interface ApiProfileEditReviewItemRes {
  expandedData?: ExpandedTopInterestDataRes | null;
  reviewItem: ProfileEditReviewItemRes;
}

export interface ApiProfileEditReviewItemResSimplePersonResponseRelatedPagedData {
  list?: ApiProfileEditReviewItemRes[];
  pagination?: Pagination;
  relatedModel?: SimplePersonResponse;
}

export interface ApiRelatedPersonAccountsRes {
  person: SimplePersonResponse;
}

export interface ApiRelatedUserAccountsRes {
  user: SimpleUserResponse;
}

export type ArticleMetaRes = UtilRequiredKeys<BaseEfiMetaRes, 'polyType'> & {
  authorName?: string | null;
  authorSlug?: string | null;
  featuredImage?: ImageResponse;
  fullPath?: string | null;
  isPlus: boolean;
  /** @format int64 */
  key: number;
  polyType: 'ArticleMetaRes';
  /** @format date-time */
  postDateGmt: string;
  primaryCategory?: SlimCategoryResponse;
  slug?: string | null;
  status?: string | null;
  title?: string | null;
};

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AssignUserToProfileEditReqBody {
  /** @format int64 */
  assignedUserKey: number;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

interface BaseApiProcessAccessForProfileInfoReq {
  $type: string;
}

type BaseApiProcessAccessForProfileInfoReqTypeMapping<Key, Type> = {
  $type: Key;
} & Type;

interface BaseBaseProfileEdit {
  $type: string;
  editType: EditType;
}

type BaseBaseProfileEditTypeMapping<Key, Type> = {
  $type: Key;
} & Type;

interface BaseEfiMetaRes {
  polyType: string;
}

type BaseEfiMetaResPolyTypeMapping<Key, Type> = {
  polyType: Key;
} & Type;

interface BaseExpandedEditReviewItemDataRes {
  $type: string;
}

type BaseExpandedEditReviewItemDataResTypeMapping<Key, Type> = {
  $type: Key;
} & Type;

export type BaseProfileEdit = BaseBaseProfileEdit &
  (
    | BaseBaseProfileEditTypeMapping<'firstName', FirstNameProfileEdit>
    | BaseBaseProfileEditTypeMapping<'lastName', LastNameProfileEdit>
    | BaseBaseProfileEditTypeMapping<'knownName', KnownNameProfileEdit>
    | BaseBaseProfileEditTypeMapping<'ncaaId', NcaaIdProfileEdit>
    | BaseBaseProfileEditTypeMapping<'bio', BioProfileEdit>
    | BaseBaseProfileEditTypeMapping<'dateOfBirth', DateOfBirthProfileEdit>
    | BaseBaseProfileEditTypeMapping<'socialMedia', SocialMediaProfileEdit>
    | BaseBaseProfileEditTypeMapping<'hometown', HometownProfileEdit>
    | BaseBaseProfileEditTypeMapping<'highSchool', HighSchoolProfileEdit>
    | BaseBaseProfileEditTypeMapping<'sportPlayed', SportPlayedProfileEdit>
    | BaseBaseProfileEditTypeMapping<'topInterest', TopInterestProfileEdit>
    | BaseBaseProfileEditTypeMapping<
        'hsClassYear',
        HighSchoolClassYearProfileEdit
      >
    | BaseBaseProfileEditTypeMapping<'vitals', VitalsProfileEdit>
    | BaseBaseProfileEditTypeMapping<'profileImage', ProfileImageProfileEdit>
    | BaseBaseProfileEditTypeMapping<'academicInfo', AcademicInfoProfileEdit>
    | BaseBaseProfileEditTypeMapping<'video', VideoProfileEdit>
    | BaseBaseProfileEditTypeMapping<'contactInfo', ContactInfoProfileEdit>
    | BaseBaseProfileEditTypeMapping<
        'secondaryContactInfo',
        SecondaryContactInfoProfileEdit
      >
    | BaseBaseProfileEditTypeMapping<
        'mailingAddress',
        MailingAddressProfileEdit
      >
    | BaseBaseProfileEditTypeMapping<'unknown', UnknownProfileEdit>
  );

export interface BioProfileEdit {
  $type: 'bio';
  editType: EditType;
  value?: string | null;
}

export enum BoltContentMarkup {
  Unknown = 'Unknown',
  BbCode = 'BbCode',
  Html = 'Html',
  Markdown = 'Markdown',
}

export interface BoltConvertMarkupRequest {
  /**
   * A string in the source markup to be converted.
   * @minLength 1
   */
  content: string;
  from: BoltContentMarkup;
  to: BoltContentMarkup;
}

export interface BoltConvertMarkupResponse {
  markup: BoltContentMarkup;
  /** The converted string in the target markup. */
  result?: string;
}

export type BoltMetaRes = UtilRequiredKeys<BaseEfiMetaRes, 'polyType'> & {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  author?: SimpleAuthorResponse;
  /** @format int64 */
  authorKey: number;
  body?: string | null;
  /** @format date-time */
  created: string;
  headline?: SimpleHeadlineResponse;
  headlineAsset?: AssetResponse;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  polyType: 'BoltMetaRes';
  title?: string;
  /** @format date-time */
  updated?: string | null;
};

export interface BoltRes {
  /** @format int64 */
  assetKey?: number | null;
  /** @format int64 */
  authorKey: number;
  /** @format date-time */
  created: string;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  title?: string;
  /** @format date-time */
  updated?: string | null;
}

export interface BoltResPagedData {
  list?: BoltRes[];
  pagination?: Pagination;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CoachDirectoryDetailResponse {
  coachDirectory: CoachDirectoryResponse;
  person?: CoachResponse;
}

export interface CoachDirectoryResponse {
  emailAddress?: string | null;
  facebook?: string | null;
  firstName: string;
  instagram?: string | null;
  /** The IPEDS NCES ID for the university the coach is employed by */
  ipedsNcesId: string;
  /** @format int32 */
  key: number;
  lastName: string;
  /** @format int64 */
  personKey: number | null;
  phoneNumber?: string | null;
  position: string;
  /** @format int32 */
  sportKey: number;
  twitter?: string | null;
  uniqueID: string;
}

export interface CoachDirectoryResponseOrganizationRosterResponseRelatedPagedData {
  list?: CoachDirectoryResponse[];
  pagination?: Pagination;
  relatedModel?: OrganizationRosterResponse;
}

export interface CoachResponse {
  /** @format int32 */
  age?: number | null;
  agency?: AgencyResponse;
  agent?: AgentPersonResponse;
  almaMater?: OrganizationResponse;
  /** @format int32 */
  almaMaterClassYear?: number | null;
  deceased: boolean;
  defaultAsset?: AssetResponse;
  defaultSport?: SportBase;
  degree?: string | null;
  description?: string | null;
  fired: boolean;
  firstName?: string;
  fullName?: string;
  highSchoolAlmaMater?: OrganizationResponse;
  /** @format int32 */
  highSchoolAlmaMaterClassYear?: number | null;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  noAgent: boolean;
  /** @format int32 */
  orgSeasonCount?: number | null;
  organization?: OrganizationResponse;
  primaryPosition?: AttributeResponse;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  secondaryAgency?: AgencyResponse;
  secondaryAgent?: AgentPersonResponse;
  secondaryPosition?: AttributeResponse;
  slug?: string;
  sports?: SportBase[];
  /** @format int32 */
  yearsActive?: number | null;
}

export interface CollectedProfileInfoRes {
  cell?: string | null;
  /** @format date-time */
  created: string;
  email?: string;
  firstName?: string;
  /** @format int32 */
  highSchoolClassYear: number;
  highSchoolFound: boolean;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  highSchoolSearch?: string | null;
  instagram?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  /** @format uuid */
  nonce: string;
  /** @format int64 */
  personKey?: number | null;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
  status: PersonCreationStatus;
  /** @format date-time */
  updated?: string | null;
  /** @format int64 */
  userKey?: number | null;
}

export enum CommentType {
  Unknown = 'Unknown',
  Public = 'Public',
  Internal = 'Internal',
  System = 'System',
}

export type ContactInfoProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'contactInfo';
  email?: string | null;
  phoneNumber?: string | null;
};

export enum ContactType {
  Unknown = 'Unknown',
  Coach = 'Coach',
  Guardian = 'Guardian',
  SecondaryGuardian = 'SecondaryGuardian',
  Representation = 'Representation',
  Counselor = 'Counselor',
}

export interface CreateProfileEditCommentReqBody {
  /** @minLength 1 */
  message: string;
  type: CommentType;
}

export interface CreateProfileEditReqBody {
  edit:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit;
}

export interface DateOfBirthProfileEdit {
  $type: 'dateOfBirth';
  editType: EditType;
  /** @format date-time */
  value?: string | null;
}

export interface DetailedBoltRes {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  author?: SimpleAuthorResponse;
  /** @format int64 */
  authorKey: number;
  body?: string | null;
  /** @format date-time */
  created: string;
  headline?: SimpleHeadlineResponse;
  headlineAsset?: AssetResponse;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  taggedClassYears?: number[] | null;
  taggedPeople?: SimplePersonResponse[] | null;
  taggedPersonKeys?: number[] | null;
  taggedSportKeys?: number[] | null;
  taggedSports?: SportResponseRecord[] | null;
  taggedTierStatuses?: TierStatuses[] | null;
  title?: string;
  /** @format date-time */
  updated?: string | null;
}

export enum EditType {
  Unknown = 'Unknown',
  Existing = 'Existing',
  Add = 'Add',
  Update = 'Update',
  Remove = 'Remove',
}

export type EfiMetaRes = BaseEfiMetaRes &
  (
    | BaseEfiMetaResPolyTypeMapping<'BoltMetaRes', BoltMetaRes>
    | BaseEfiMetaResPolyTypeMapping<
        'IndustryRankingMetaRes',
        IndustryRankingMetaRes
      >
    | BaseEfiMetaResPolyTypeMapping<
        'ServiceRankingMetaRes',
        ServiceRankingMetaRes
      >
    | BaseEfiMetaResPolyTypeMapping<'GraphicEditMetaRes', GraphicEditMetaRes>
    | BaseEfiMetaResPolyTypeMapping<'NilValuationMetaRes', NilValuationMetaRes>
    | BaseEfiMetaResPolyTypeMapping<'ArticleMetaRes', ArticleMetaRes>
  );

export interface EliteFeedItemRes {
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?:
    | BoltMetaRes
    | IndustryRankingMetaRes
    | ServiceRankingMetaRes
    | GraphicEditMetaRes
    | NilValuationMetaRes
    | ArticleMetaRes
    | null;
  /** @format date-time */
  storedDate: string;
  /** @format int64 */
  typeKey: number;
}

export interface EliteFeedItemResPagedData {
  list?: EliteFeedItemRes[];
  pagination?: Pagination;
}

export type ExpandedEditReviewItemDataRes = BaseExpandedEditReviewItemDataRes &
  BaseExpandedEditReviewItemDataResTypeMapping<
    'topInterest',
    ExpandedTopInterestDataRes
  >;

export type ExpandedTopInterestDataRes = UtilRequiredKeys<
  BaseExpandedEditReviewItemDataRes,
  '$type'
> & {
  $type: 'topInterest';
  coaches?: SimplePersonResponse[];
  organization?: OrganizationResponse;
  primaryPerson?: SimplePersonResponse;
};

export enum FeedItemGenerationTypes {
  Unknown = 'Unknown',
  Meta = 'Meta',
  PersonFeed = 'PersonFeed',
  OrganizationFeed = 'OrganizationFeed',
  UserFeed = 'UserFeed',
}

export enum FeedItemStatuses {
  Unknown = 'Unknown',
  Stale = 'Stale',
  GeneratingMeta = 'GeneratingMeta',
  GeneratingPersonFeeds = 'GeneratingPersonFeeds',
  GeneratingOrganizationFeeds = 'GeneratingOrganizationFeeds',
  GeneratingUserFeeds = 'GeneratingUserFeeds',
  FlushingCache = 'FlushingCache',
  QueueNotifications = 'QueueNotifications',
  Updated = 'Updated',
  Error = 'Error',
}

export enum FeedItemTypes {
  Unknown = 'Unknown',
  Article = 'Article',
  NilValuation = 'NilValuation',
  NilDeal = 'NilDeal',
  IndustryRanking = 'IndustryRanking',
  GraphicEdit = 'GraphicEdit',
  ServiceRanking = 'ServiceRanking',
  Bolt = 'Bolt',
}

export interface FileRes {
  domain?: string;
  fileName?: string;
  fullUrl?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  path?: string | null;
  tags?: FileTag[];
}

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export interface FileTag {
  key?: string;
  value?: string;
}

export interface FirstNameProfileEdit {
  $type: 'firstName';
  editType: EditType;
  value?: string | null;
}

export type GraphicEditMetaRes = UtilRequiredKeys<
  BaseEfiMetaRes,
  'polyType'
> & {
  completedImage?: FileResponse;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  polyType: 'GraphicEditMetaRes';
  /** @format int64 */
  requestedTimestamp: number;
  status: AnnouncementImageStatus;
};

export interface HighSchoolClassYearProfileEdit {
  $type: 'hsClassYear';
  editType: EditType;
  /** @format int32 */
  value?: number | null;
}

export type HighSchoolProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'highSchool';
  /** @format int32 */
  organizationKey?: number | null;
  organizationName?: string | null;
};

export type HometownProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'hometown';
  /** @format int32 */
  hometownCityKey?: number | null;
};

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface IndustryRankingMetaRes {
  industryRanking?: IndustryRankingRes;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  polyType: 'IndustryRankingMetaRes';
  position?: PositionResponse;
  state?: StateResponseRecord;
  /** @format int64 */
  timestamp: number;
  type?: string;
}

export interface IndustryRankingRes {
  on3?: ServiceRankingRes;
  espn?: ServiceRankingRes;
  fiveStarPlus: boolean;
  industryRanking?: ServiceRankingRes;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  rankingType: RankingType;
  rivals?: ServiceRankingRes;
  tfs?: ServiceRankingRes;
  /** @format int64 */
  timestamp: number;
}

export interface InitialProfileInfoReq {
  /**
   * @minLength 0
   * @maxLength 20
   */
  cell?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string;
  /** @format int32 */
  highSchoolClassYear: number;
  highSchoolFound: boolean;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  highSchoolSearch?: string | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  instagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string;
  /** @format int32 */
  sportKey?: number | null;
}

export interface InterestVisitProfileEdit {
  coachPersonKeys?: number[] | null;
  /** @format date-time */
  dateOccurred: string;
  official?: boolean | null;
  /** @format int64 */
  visitKey?: number | null;
}

export interface KnownNameProfileEdit {
  $type: 'knownName';
  editType: EditType;
  value?: string | null;
}

export interface LastNameProfileEdit {
  $type: 'lastName';
  editType: EditType;
  value?: string | null;
}

export interface LinkPersonToProfileInfoReqBody {
  /** @format int64 */
  personKey?: number | null;
}

export type MailingAddressProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  addressLine1?: string | null;
  addressLine2?: string | null;
  $type: 'mailingAddress';
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
};

export interface MeasurementTypeResponse {
  isTrackAndField: boolean;
  /** @format int64 */
  key: number;
  lowerIsBetter: boolean;
  /** @format int64 */
  measurementTypeKey: number;
  sort: SortMeasurementType;
  type: string | null;
  units: string | null;
}

export interface NcaaIdProfileEdit {
  $type: 'ncaaId';
  editType: EditType;
  value?: string | null;
}

export interface NilDisputeRequest {
  otherInformation?: string | null;
  /** @format int64 */
  personKey: number;
  type: NilDisputeType;
}

export interface NilDisputeResponse {
  /** @format date-time */
  date: string;
  /** @format int64 */
  key: number;
  otherInformation?: string | null;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  reviewedNotes?: string | null;
  status: NilDisputeStatus;
  type: NilDisputeType;
}

export interface NilDisputeResponsePagedData {
  list?: NilDisputeResponse[];
  pagination?: Pagination;
}

export enum NilDisputeStatus {
  Unknown = 'Unknown',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
}

export enum NilDisputeType {
  Unknown = 'Unknown',
  MakingMore = 'MakingMore',
  AdditionalOffers = 'AdditionalOffers',
  Other = 'Other',
}

export type NilValuationMetaRes = UtilRequiredKeys<
  BaseEfiMetaRes,
  'polyType'
> & {
  /** @format date-time */
  date: string;
  increase?: boolean | null;
  /** @format int64 */
  key: number;
  polyType: 'NilValuationMetaRes';
  /** @format int64 */
  previousValuation?: number | null;
  /** @format int64 */
  valuation: number;
  /** @format int64 */
  valuationChange: number;
  /** @format double */
  valuationChangePercent: number;
};

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface OrganizationRosterResponse {
  orgResponse?: OrganizationResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  year: number;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export enum PersonCreationStatus {
  Unknown = 'Unknown',
  Submitted = 'Submitted',
  Abandoned = 'Abandoned',
  Paid = 'Paid',
  Rejected = 'Rejected',
  Claimed = 'Claimed',
  Processed = 'Processed',
  Verified = 'Verified',
  Lapsed = 'Lapsed',
  Legacy = 'Legacy',
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface ProcessAccessReqBody {
  accessInfo:
    | ApiProcessPianoConversionForProfileInfoReq
    | ApiProcessUserAccessForProfileInfoReq;
}

export interface ProfileAccountSummaryRes {
  access?: SimpleUserAccessRes;
  account: ProfileManagementAccountRes;
  collectedProfileInfo?: CollectedProfileInfoRes;
  owner?: SimpleUserResponse;
  person?: SimplePersonResponse;
  seats?: ProfileSeatRes[];
}

export interface ProfileEditCommentRes {
  /** @format date-time */
  created: string;
  /** @format int64 */
  key: number;
  message?: string | null;
  /** @format int64 */
  reviewItemKey: number;
  type: CommentType;
  /** @format int64 */
  userKey?: number | null;
}

export interface ProfileEditReviewItemAdminRes {
  assignedUser: SimpleUserResponse;
  /** @format int64 */
  assignedUserKey?: number | null;
  /** @format date-time */
  created: string;
  /** @format int64 */
  key: number;
  newValue?:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit;
  oldValue?:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit
    | null;
  person: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  requestingUser: SimpleUserResponse;
  /** @format int64 */
  requestingUserKey?: number | null;
  status: ReviewItemStatus;
  /** @format date-time */
  updated?: string | null;
  valueModified: string;
}

export interface ProfileEditReviewItemAdminResProfileEditsRelatedRelatedPagedData {
  list?: ProfileEditReviewItemAdminRes[];
  pagination?: Pagination;
  relatedModel?: ProfileEditsRelated;
}

export interface ProfileEditReviewItemRes {
  /** @format int64 */
  assignedUserKey?: number | null;
  /** @format date-time */
  created: string;
  /** @format int64 */
  key: number;
  newValue?:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit;
  oldValue?:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit
    | null;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  requestingUserKey?: number | null;
  status: ReviewItemStatus;
  /** @format date-time */
  updated?: string | null;
  valueModified: string;
}

export enum ProfileEditType {
  Unknown = 'unknown',
  FirstName = 'firstName',
  LastName = 'lastName',
  KnownName = 'knownName',
  NcaaId = 'ncaaId',
  Bio = 'bio',
  DateOfBirth = 'dateOfBirth',
  SocialMedia = 'socialMedia',
  HighSchool = 'highSchool',
  SportPlayed = 'sportPlayed',
  TopInterest = 'topInterest',
  HsClassYear = 'hsClassYear',
  Vitals = 'vitals',
  ProfileImage = 'profileImage',
  AcademicInfo = 'academicInfo',
  Video = 'video',
  ContactInfo = 'contactInfo',
  SecondaryContactInfo = 'secondaryContactInfo',
  MailingAddress = 'mailingAddress',
}

export interface ProfileEditsRelated {
  measurementTypes: MeasurementTypeResponse[];
  positions: PositionResponse[];
  sports: SportResponseRecord[];
}

export interface ProfileImageProfileEdit {
  $type: 'profileImage';
  editType: EditType;
  file?: FileRes;
}

export interface ProfileManagementAccountRes {
  /** @format int64 */
  collectedInfoKey?: number | null;
  /** @format date-time */
  created: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  ownerUserKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int64 */
  userAccessKey?: number | null;
}

export interface ProfileSeatRes {
  seat: SimpleProfileSeatRes;
  user?: SimpleUserResponse;
}

export enum ProfileSeatRole {
  Unknown = 'Unknown',
  Athlete = 'Athlete',
  Guardian = 'Guardian',
  Coach = 'Coach',
  Admin = 'Admin',
}

export interface RankChangeRes {
  type: PlayerRatingChange;
  /** @format int32 */
  value?: number | null;
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RatingChangeRes {
  type: PlayerRatingChange;
  /** @format double */
  value?: number | null;
}

export interface RebuildPersonFeedRequest {
  /** @minItems 1 */
  entityTypes?: FeedItemTypes[];
}

export interface RenovateBoltReq {
  /** @format int64 */
  assetKey?: number | null;
  /**
   * @format int64
   * @min 1
   */
  authorKey: number;
  body?: string | null;
  /**
   * Taken in preference to On3.Shared.Models.Requests.Main.RenovateBoltReq.HeadlineUrl
   * @format int64
   */
  headlineKey?: number | null;
  /** Use this if the key is not known or a token input doesn't exist */
  headlineUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  taggedClassYears?: number[] | null;
  taggedPersonKeys?: number[] | null;
  taggedSportKeys?: number[] | null;
  taggedTierStatuses?: TierStatuses[] | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  title?: string | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export enum ReviewItemStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved',
  AutoApproved = 'AutoApproved',
  Error = 'Error',
}

export type SecondaryContactInfoProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'secondaryContactInfo';
  contactType: ContactType;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
};

export interface SendEliteTestNotificationRequest {
  condition?: string | null;
  /** @format int64 */
  eliteFeedItemKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  syncUserEliteSettings: boolean;
  topic?: string | null;
  /** @format int64 */
  userKey: number;
}

export interface ServiceRankingMetaRes {
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  polyType: 'ServiceRankingMetaRes';
  position?: PositionResponse;
  serviceRanking?: ServiceRankingRes;
  state?: StateResponseRecord;
  /** @format int64 */
  timestamp: number;
  type?: string;
}

export interface ServiceRankingRes {
  nationalChange?: RankChangeRes;
  /** @format int32 */
  nationalRank?: number | null;
  positionChange?: RankChangeRes;
  /** @format int32 */
  positionRank?: number | null;
  previousRanking?: ServiceRankingRes;
  /** @format double */
  rating?: number | null;
  ratingChange?: RatingChangeRes;
  /** @format int32 */
  stars?: number | null;
  stateChange?: RankChangeRes;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export interface SimpleAuthorResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  slug?: string | null;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SimpleProfileSeatRes {
  /** @format date-time */
  created: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  profileManagementAccountKey: number;
  role: ProfileSeatRole;
  /** @format date-time */
  updated?: string | null;
  /** @format int64 */
  userKey?: number | null;
}

export interface SimpleUserAccessRes {
  canRevokeAccess: boolean;
  /** @format date-time */
  expireDate?: string | null;
  granted: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  resourceKey: number;
  /** @format int64 */
  resourceTermKey?: number | null;
  revoked: boolean;
  /** @format date-time */
  startDate: string;
  /** @format int64 */
  userKey: number;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SlimCategoryResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface SlimOrganizationRes {
  fullName?: string;
  /** @format int32 */
  key: number;
}

export interface SocialMediaProfileEdit {
  $type: 'socialMedia';
  editType: EditType;
  socialType: SocialType;
  value?: string | null;
}

export enum SocialType {
  Unknown = 'Unknown',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export enum SortMeasurementType {
  Latest = 'Latest',
  Lowest = 'Lowest',
  Highest = 'Highest',
}

export interface SportBase {
  /** @format int32 */
  key: number;
  name?: string;
}

export type SportPlayedProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'sportPlayed';
  /** @format int32 */
  firstYear?: number | null;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int32 */
  lastYear?: number | null;
  /** @format int32 */
  primaryPositionKey?: number | null;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  /** @format int32 */
  sportKey: number;
};

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export enum TierStatuses {
  Unknown = 'Unknown',
  Elite = 'Elite',
  All = 'All',
}

export type TopInterestProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'topInterest';
  contacted: boolean;
  offer: boolean;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  primaryPersonKey?: number | null;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  visits?: InterestVisitProfileEdit[] | null;
};

export type UnknownProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'unknown';
};

export interface UpdateAdminNilDisputeRequest {
  /** @format int64 */
  key: number;
  reviewedNotes?: string | null;
  status: NilDisputeStatus;
}

export interface UpdateProfileEditCommentReqBody {
  /** @minLength 1 */
  message: string;
}

export interface UpdateProfileEditReqBody {
  edit:
    | FirstNameProfileEdit
    | LastNameProfileEdit
    | KnownNameProfileEdit
    | NcaaIdProfileEdit
    | BioProfileEdit
    | DateOfBirthProfileEdit
    | SocialMediaProfileEdit
    | HometownProfileEdit
    | HighSchoolProfileEdit
    | SportPlayedProfileEdit
    | TopInterestProfileEdit
    | HighSchoolClassYearProfileEdit
    | VitalsProfileEdit
    | ProfileImageProfileEdit
    | AcademicInfoProfileEdit
    | VideoProfileEdit
    | ContactInfoProfileEdit
    | SecondaryContactInfoProfileEdit
    | MailingAddressProfileEdit
    | UnknownProfileEdit;
}

export interface UpdateProfileInfoStatusReqBody {
  status: PersonCreationStatus;
}

export interface UpdateProfileSearchInfoReqBody {
  /**
   * @minLength 0
   * @maxLength 20
   */
  cell?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string;
  /** @format int32 */
  highSchoolClassYear: number;
  highSchoolFound: boolean;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  highSchoolSearch?: string | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  instagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string;
  /** @format int32 */
  sportKey?: number | null;
}

export interface V1ArticlesRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1BoltsListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1EliteFeedItemsListParams {
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  sendNotification?: boolean;
  statuses?: FeedItemStatuses[];
  /** @format int64 */
  typeKey?: number;
  types?: FeedItemTypes[];
}

export interface V1EliteFeedItemsSendNotificationsCreateParams {
  /** @default true */
  activityFeed?: boolean;
  /** @format int64 */
  eliteFeedItemKey: number;
}

export interface V1GraphicEditsRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1NilDisputesDeleteParams {
  /** @format int64 */
  playerKey?: number;
}

export interface V1NilDisputesListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1NilValuationsRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1NotificationsSyncTopicsUpdate2Params {
  /** @format int64 */
  userKeyStart?: number;
}

export interface V1OrganizationsCoachDirectoryDetailParams {
  /** @format int32 */
  organizationKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
}

export interface V1OrganizationsValidateFeedHeadlinesDetailParams {
  /** @format int32 */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1PeopleNilDisputesDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1PeopleProfileEditsDetailParams {
  editTypes?: EditType[];
  /** @format int64 */
  personKey: number;
  statuses?: ReviewItemStatus[];
  types?: string[];
}

export interface V1PersonCreateRequestsListParams {
  email?: string;
  /** @format int32 */
  highSchoolClassYear?: number;
  highSchoolFound?: boolean;
  /** @format int32 */
  highSchoolOrganizationKey?: number;
  /** @format int32 */
  limit?: number;
  /** @format uuid */
  nonce?: string;
  /** @format int32 */
  page?: number;
  /** @format int64 */
  personKey?: number;
  status?: PersonCreationStatus;
  /** @format int64 */
  userKey?: number;
}

export interface V1ProfileEditsAttachFileUpdatePayload {
  /** @format binary */
  file: File;
}

export interface V1ProfileEditsListParams {
  /** @format int64 */
  assignedUserKey?: number;
  /**
   * Allowed number of items per page.
   * 1, 5, 10, 25, 50 are valid if provided.
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey?: number;
  /** @format int64 */
  requestingUserKey?: number;
  statuses?: ReviewItemStatus[];
  types?: ProfileEditType[];
}

export interface V1RankingsSyncPersonFeedsCreateParams {
  /** @default true */
  industry?: boolean;
  /** @format int32 */
  rankingKey: number;
  /** @default true */
  sendNotification?: boolean;
}

export type VideoProfileEdit = UtilRequiredKeys<
  BaseBaseProfileEdit,
  '$type'
> & {
  $type: 'video';
  /** @format int32 */
  order?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sourceUrl: string;
  /**
   * @minLength 0
   * @maxLength 512
   */
  thumbnail?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /** @format int64 */
  videoKey?: number | null;
};

export interface VitalsProfileEdit {
  $type: 'vitals';
  /** @format date-time */
  dateOccurred?: string | null;
  editType: EditType;
  /** @format int64 */
  eventKey?: number | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  measurementTypeKey: number;
  value?: string | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1AdminProfileEditsApproveUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request PUT:/elite/v1/admin/profile-edits/{editKey}/approve
 * @secure
 */
export const v1AdminProfileEditsApproveUpdate = (
  client: AxiosInstance,
  editKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/admin/profile-edits/${editKey}/approve`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1AdminProfileEditsAssignUserUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request PUT:/elite/v1/admin/profile-edits/{editKey}/assign-user
 * @secure
 */
export const v1AdminProfileEditsAssignUserUpdate = (
  client: AxiosInstance,
  editKey: number,
  data: AssignUserToProfileEditReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/admin/profile-edits/${editKey}/assign-user`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1AdminProfileEditsRejectUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request PUT:/elite/v1/admin/profile-edits/{editKey}/reject
 * @secure
 */
export const v1AdminProfileEditsRejectUpdate = (
  client: AxiosInstance,
  editKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/admin/profile-edits/${editKey}/reject`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsConvertMarkupCreate
 * @summary Converts a given string from one markup language to another.
 * @request POST:/elite/v1/bolts/convert-markup
 * @secure
 */
export const v1BoltsConvertMarkupCreate = (
  client: AxiosInstance,
  data: BoltConvertMarkupRequest,
  params: RequestParams = {},
) =>
  client
    .request<BoltConvertMarkupResponse>({
      url: `/elite/v1/bolts/convert-markup`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/bolts/{key}
 * @secure
 */
export const v1BoltsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DetailedBoltRes>({
      url: `/elite/v1/bolts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/bolts
 * @secure
 */
export const v1BoltsList = (
  client: AxiosInstance,
  query: V1BoltsListParams,
  params: RequestParams = {},
) =>
  client
    .request<BoltResPagedData>({
      url: `/elite/v1/bolts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/bolts
 * @secure
 */
export const v1BoltsUpdate = (
  client: AxiosInstance,
  data: RenovateBoltReq,
  params: RequestParams = {},
) =>
  client
    .request<DetailedBoltRes>({
      url: `/elite/v1/bolts`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CoachDirectory
 * @name V1CoachDirectoryDetail
 * @summary Gets details of a specific coach in the directory
 * @request GET:/elite/v1/coach-directory/{coachDirectoryKey}
 * @secure
 */
export const v1CoachDirectoryDetail = (
  client: AxiosInstance,
  coachDirectoryKey: string,
  params: RequestParams = {},
) =>
  client
    .request<CoachDirectoryDetailResponse>({
      url: `/elite/v1/coach-directory/${coachDirectoryKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/elite-feed-items/{eliteFeedItemKey}
 * @secure
 */
export const v1EliteFeedItemsDetail = (
  client: AxiosInstance,
  eliteFeedItemKey: number,
  params: RequestParams = {},
) =>
  client
    .request<EliteFeedItemRes>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsGenerateCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/elite-feed-items/{eliteFeedItemKey}/generate/{feedItemGenerationType}
 * @secure
 */
export const v1EliteFeedItemsGenerateCreate = (
  client: AxiosInstance,
  eliteFeedItemKey: number,
  feedItemGenerationType: FeedItemGenerationTypes,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}/generate/${feedItemGenerationType}`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/elite-feed-items
 * @secure
 */
export const v1EliteFeedItemsList = (
  client: AxiosInstance,
  query: V1EliteFeedItemsListParams,
  params: RequestParams = {},
) =>
  client
    .request<EliteFeedItemResPagedData>({
      url: `/elite/v1/elite-feed-items`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsSendNotificationsCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/elite-feed-items/{eliteFeedItemKey}/send-notifications
 * @secure
 */
export const v1EliteFeedItemsSendNotificationsCreate = (
  client: AxiosInstance,
  { eliteFeedItemKey, ...query }: V1EliteFeedItemsSendNotificationsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}/send-notifications`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsSyncTopicsUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/notifications/{userKey}/sync-topics
 * @secure
 */
export const v1NotificationsSyncTopicsUpdate = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/elite/v1/notifications/${userKey}/sync-topics`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsSyncTopicsUpdate2
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/notifications/sync-topics
 * @originalName v1NotificationsSyncTopicsUpdate
 * @duplicate
 * @secure
 */
export const v1NotificationsSyncTopicsUpdate2 = (
  client: AxiosInstance,
  query: V1NotificationsSyncTopicsUpdate2Params,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/elite/v1/notifications/sync-topics`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsTestCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/notifications/test
 * @secure
 */
export const v1NotificationsTestCreate = (
  client: AxiosInstance,
  data: SendEliteTestNotificationRequest,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/elite/v1/notifications/test`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CoachDirectory
 * @name V1OrganizationsCoachDirectoryDetail
 * @summary Gets the coach directory for a given organization
 * @request GET:/elite/v1/organizations/{organizationKey}/coach-directory
 * @secure
 */
export const v1OrganizationsCoachDirectoryDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: V1OrganizationsCoachDirectoryDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CoachDirectoryResponseOrganizationRosterResponseRelatedPagedData>({
      url: `/elite/v1/organizations/${organizationKey}/coach-directory`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1PeopleProfileEditsCreate
 * @summary  (Roles: registered user)
 * @request POST:/elite/v1/people/{personKey}/profile-edits
 * @secure
 */
export const v1PeopleProfileEditsCreate = (
  client: AxiosInstance,
  personKey: number,
  data: CreateProfileEditReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/people/${personKey}/profile-edits`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1PeopleProfileEditsDetail
 * @summary  (Roles: registered user)
 * @request GET:/elite/v1/people/{personKey}/profile-edits
 * @secure
 */
export const v1PeopleProfileEditsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleProfileEditsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemResSimplePersonResponseRelatedPagedData>({
      url: `/elite/v1/people/${personKey}/profile-edits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AccountManagement
 * @name V1PeopleProfilesDetail
 * @summary Get the profile accounts for a player as an admin
 * @request GET:/elite/v1/people/{personKey}/profiles
 * @secure
 */
export const v1PeopleProfilesDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileAccountSummaryResApiRelatedPersonAccountsResRelatedPagedData>(
      {
        url: `/elite/v1/people/${personKey}/profiles`,
        method: 'GET',
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1PersonCreateRequestsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, cs_admin)
 * @request GET:/elite/v1/person-create-requests
 * @secure
 */
export const v1PersonCreateRequestsList = (
  client: AxiosInstance,
  query: V1PersonCreateRequestsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminCollectedProfileInfoResPagedData>({
      url: `/elite/v1/person-create-requests`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditCommentsCreate
 * @summary  (Roles: registered user)
 * @request POST:/elite/v1/profile-edit/{editKey}/comments
 * @secure
 */
export const v1ProfileEditCommentsCreate = (
  client: AxiosInstance,
  editKey: number,
  data: CreateProfileEditCommentReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditCommentRes>({
      url: `/elite/v1/profile-edit/${editKey}/comments`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditCommentsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request DELETE:/elite/v1/profile-edit-comments/{commentKey}
 * @secure
 */
export const v1ProfileEditCommentsDelete = (
  client: AxiosInstance,
  commentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/elite/v1/profile-edit-comments/${commentKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditCommentsDetail
 * @summary  (Roles: registered user)
 * @request GET:/elite/v1/profile-edit-comments/{commentKey}
 * @secure
 */
export const v1ProfileEditCommentsDetail = (
  client: AxiosInstance,
  commentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditCommentRes>({
      url: `/elite/v1/profile-edit-comments/${commentKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditCommentsUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/elite/v1/profile-edit-comments/{commentKey}
 * @secure
 */
export const v1ProfileEditCommentsUpdate = (
  client: AxiosInstance,
  commentKey: number,
  data: UpdateProfileEditCommentReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditCommentRes>({
      url: `/elite/v1/profile-edit-comments/${commentKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsAttachFileUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/elite/v1/profile-edits/{editKey}/attach-file
 * @secure
 */
export const v1ProfileEditsAttachFileUpdate = (
  client: AxiosInstance,
  editKey: number,
  data: V1ProfileEditsAttachFileUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/profile-edits/${editKey}/attach-file`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsCommentsDetail
 * @summary  (Roles: registered user)
 * @request GET:/elite/v1/profile-edits/{editKey}/comments
 * @secure
 */
export const v1ProfileEditsCommentsDetail = (
  client: AxiosInstance,
  editKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditCommentResApiProfileEditReviewItemResRelatedPagedData>(
      {
        url: `/elite/v1/profile-edits/${editKey}/comments`,
        method: 'GET',
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request DELETE:/elite/v1/profile-edits/{editKey}
 * @secure
 */
export const v1ProfileEditsDelete = (
  client: AxiosInstance,
  editKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/elite/v1/profile-edits/${editKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsDetail
 * @summary  (Roles: registered user)
 * @request GET:/elite/v1/profile-edits/{editKey}
 * @secure
 */
export const v1ProfileEditsDetail = (
  client: AxiosInstance,
  editKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/profile-edits/${editKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsList
 * @summary  (Roles: profile_edit, rdb3, rdb4, rdb5, administrator, developer)
 * @request GET:/elite/v1/profile-edits
 * @secure
 */
export const v1ProfileEditsList = (
  client: AxiosInstance,
  query: V1ProfileEditsListParams,
  params: RequestParams = {},
) =>
  client
    .request<ProfileEditReviewItemAdminResProfileEditsRelatedRelatedPagedData>({
      url: `/elite/v1/profile-edits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonEdits
 * @name V1ProfileEditsUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/elite/v1/profile-edits/{editKey}
 * @secure
 */
export const v1ProfileEditsUpdate = (
  client: AxiosInstance,
  editKey: number,
  data: UpdateProfileEditReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileEditReviewItemRes>({
      url: `/elite/v1/profile-edits/${editKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoClaimPersonCreate
 * @summary  (Roles: registered user)
 * @request POST:/elite/v1/profile-info/claim-person
 * @secure
 */
export const v1ProfileInfoClaimPersonCreate = (
  client: AxiosInstance,
  data: ApiClaimProfileInfoReq,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/claim-person`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoCollectInitialCreate
 * @request POST:/elite/v1/profile-info/collect-initial
 */
export const v1ProfileInfoCollectInitialCreate = (
  client: AxiosInstance,
  data: InitialProfileInfoReq,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/collect-initial`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoEnsureLegacyCreate
 * @summary  (Roles: developer)
 * @request POST:/elite/v1/profile-info/ensure-legacy
 * @secure
 */
export const v1ProfileInfoEnsureLegacyCreate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<AdminCollectedProfileInfoResPagedData>({
      url: `/elite/v1/profile-info/ensure-legacy`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNDetail
 * @request GET:/elite/v1/profile-info/{key}-n-{nonce}
 */
export const v1ProfileInfoNDetail = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNLinkPersonUpdate
 * @request PUT:/elite/v1/profile-info/{key}-n-{nonce}/link-person
 */
export const v1ProfileInfoNLinkPersonUpdate = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  data: LinkPersonToProfileInfoReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}/link-person`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNLinkUserUpdate
 * @request PUT:/elite/v1/profile-info/{key}-n-{nonce}/link-user
 */
export const v1ProfileInfoNLinkUserUpdate = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}/link-user`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNProcessAccessUpdate
 * @request PUT:/elite/v1/profile-info/{key}-n-{nonce}/process-access
 */
export const v1ProfileInfoNProcessAccessUpdate = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  data: ProcessAccessReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}/process-access`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNUpdateProfileSearchUpdate
 * @request PUT:/elite/v1/profile-info/{key}-n-{nonce}/update-profile-search
 */
export const v1ProfileInfoNUpdateProfileSearchUpdate = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  data: UpdateProfileSearchInfoReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}/update-profile-search`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PlayerInformationCollection
 * @name V1ProfileInfoNUpdateStatusUpdate
 * @request PUT:/elite/v1/profile-info/{key}-n-{nonce}/update-status
 */
export const v1ProfileInfoNUpdateStatusUpdate = (
  client: AxiosInstance,
  key: number,
  nonce: string,
  data: UpdateProfileInfoStatusReqBody,
  params: RequestParams = {},
) =>
  client
    .request<ApiCollectedProfileInfoRes>({
      url: `/elite/v1/profile-info/${key}-n-${nonce}/update-status`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AccountManagement
 * @name V1ProfilesList
 * @summary Get the profile accounts for an authorized user
 * @request GET:/elite/v1/profiles
 * @secure
 */
export const v1ProfilesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileAccountSummaryResApiRelatedUserAccountsResRelatedPagedData>(
      {
        url: `/elite/v1/profiles`,
        method: 'GET',
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AccountManagement
 * @name V1UsersProfilesDetail
 * @summary Get the profile accounts for an authorized user as an admin
 * @request GET:/elite/v1/users/{userKey}/profiles
 * @secure
 */
export const v1UsersProfilesDetail = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ApiProfileAccountSummaryResApiRelatedUserAccountsResRelatedPagedData>(
      {
        url: `/elite/v1/users/${userKey}/profiles`,
        method: 'GET',
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputeRequestCreate
 * @summary Creates NIL dispute for a player
 * @request POST:/nil/v1/nil-dispute-request
 * @secure
 */
export const v1NilDisputeRequestCreate = (
  client: AxiosInstance,
  data: NilDisputeRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-dispute-request`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesDelete
 * @summary Deletes NIL dispute for given NIL dispute key
 * @request DELETE:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesDelete = (
  client: AxiosInstance,
  query: V1NilDisputesDeleteParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/nil-disputes`,
      method: 'DELETE',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesDetail
 * @summary Gets given dispute for key for admin
 * @request GET:/nil/v1/nil-disputes/{key}
 * @secure
 */
export const v1NilDisputesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-disputes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesList
 * @summary Gets list of disputes for db admin
 * @request GET:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesList = (
  client: AxiosInstance,
  query: V1NilDisputesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/nil-disputes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesUpdate
 * @summary Modifies NIL dispute for when admin is reviewing
 * @request PUT:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesUpdate = (
  client: AxiosInstance,
  data: UpdateAdminNilDisputeRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-disputes`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1PeopleNilDisputesDetail
 * @summary Gets Player's NIL Disputes
 * @request GET:/nil/v1/people/{personKey}/nil-disputes
 * @secure
 */
export const v1PeopleNilDisputesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleNilDisputesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/people/${personKey}/nil-disputes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1ArticlesRebuildFeedLocCreate
 * @request POST:/rdb/v1/articles/rebuild-feed-loc
 */
export const v1ArticlesRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1ArticlesRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/articles/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1GraphicEditsRebuildFeedLocCreate
 * @request POST:/rdb/v1/graphic-edits/rebuild-feed-loc
 */
export const v1GraphicEditsRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1GraphicEditsRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/graphic-edits/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1NilValuationsRebuildFeedLocCreate
 * @request POST:/rdb/v1/nil-valuations/rebuild-feed-loc
 */
export const v1NilValuationsRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1NilValuationsRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/nil-valuations/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationFeed
 * @name V1OrganizationsRebuildFeedCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/organizations/{organizationKey}/rebuild-feed
 * @secure
 */
export const v1OrganizationsRebuildFeedCreate = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/organizations/${organizationKey}/rebuild-feed`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationFeed
 * @name V1OrganizationsValidateFeedHeadlinesDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/organizations/{organizationKey}/validate-feed-headlines
 * @secure
 */
export const v1OrganizationsValidateFeedHeadlinesDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: V1OrganizationsValidateFeedHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/rdb/v1/organizations/${organizationKey}/validate-feed-headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1PeopleRebuildFeedCreate
 * @summary  (Roles: developer, rdb5, rdb4, elite_admin)
 * @request POST:/rdb/v1/people/{personKey}/rebuild-feed
 * @secure
 */
export const v1PeopleRebuildFeedCreate = (
  client: AxiosInstance,
  personKey: number,
  data: RebuildPersonFeedRequest,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/people/${personKey}/rebuild-feed`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1PeopleRebuildFeedLocCreate
 * @request POST:/rdb/v1/people/{personKey}/rebuild-feed-loc
 */
export const v1PeopleRebuildFeedLocCreate = (
  client: AxiosInstance,
  personKey: number,
  data: RebuildPersonFeedRequest,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/people/${personKey}/rebuild-feed-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1RankingsSyncPersonFeedsCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/rankings/{rankingKey}/sync-person-feeds
 * @secure
 */
export const v1RankingsSyncPersonFeedsCreate = (
  client: AxiosInstance,
  { rankingKey, ...query }: V1RankingsSyncPersonFeedsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/rankings/${rankingKey}/sync-person-feeds`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);
