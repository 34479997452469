export type MeasurementType = {
  key: number;
  type: string;
  sort: number;
  units: string;
  track_and_field: boolean;
  lower_is_better: boolean;
};

export const measurementTypes = [
  {
    key: 1,
    type: 'Height',
    sort: 0,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 2,
    type: 'Weight',
    sort: 0,
    units: 'lbs',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 3,
    type: 'Hand',
    sort: 2,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 4,
    type: 'Vertical',
    sort: 2,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 5,
    type: 'Arm',
    sort: 2,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 6,
    type: '40 Yard Dash',
    sort: 1,
    units: 'secs',
    track_and_field: false,
    lower_is_better: true,
  },
  {
    key: 7,
    type: '20 Yard Shuttle',
    sort: 1,
    units: 'secs',
    track_and_field: false,
    lower_is_better: true,
  },
  {
    key: 8,
    type: 'Shot Put',
    sort: 2,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 9,
    type: '100 Meters',
    sort: 1,
    units: 'secs',
    track_and_field: true,
    lower_is_better: true,
  },
  {
    key: 13,
    type: '200 Meters',
    sort: 1,
    units: 'secs',
    track_and_field: true,
    lower_is_better: true,
  },
  {
    key: 14,
    type: '3-Cone',
    sort: 1,
    units: 'secs',
    track_and_field: false,
    lower_is_better: true,
  },
  {
    key: 15,
    type: 'Broad Jump',
    sort: 2,
    units: 'inches',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 16,
    type: 'Bench Press',
    sort: 2,
    units: 'reps',
    track_and_field: false,
    lower_is_better: false,
  },
  {
    key: 17,
    type: '60 Yard Shuttle',
    sort: 1,
    units: 'secs',
    track_and_field: false,
    lower_is_better: true,
  },
  {
    key: 18,
    type: '4x100 Relay',
    sort: 1,
    units: 'secs',
    track_and_field: true,
    lower_is_better: true,
  },
  {
    key: 19,
    type: '4x200 Relay',
    sort: 1,
    units: 'secs',
    track_and_field: true,
    lower_is_better: true,
  },
  {
    key: 20,
    type: '4x400 Relay',
    sort: 1,
    units: 'secs',
    track_and_field: true,
    lower_is_better: true,
  },
  {
    key: 21,
    type: 'Long Jump',
    sort: 2,
    units: 'inches',
    track_and_field: true,
    lower_is_better: false,
  },
];
