export const formatHeightToString = (
  height?: string | number | null,
  part?: 'feet' | 'inches',
): string => {
  if (!height) return '';

  if (typeof height === 'string') {
    height = parseInt(height);
  }

  const feet = Math.floor(height / 12);
  const inches = height % 12;

  if (part === 'feet') return `${feet}`;
  if (part === 'inches') return `${inches}`;

  return `${feet}-${inches}`;
};

export const formatHeightToNumber = (
  height?: string | null,
): number | undefined => {
  if (!height) return;

  const [feet, inches] = height.split('-');

  return parseInt(feet) * 12 + parseInt(inches);
};
