import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface AdvancedOrgSearchResponse {
  abbreviation?: string | null;
  city?: string | null;
  defaultAssetUrl?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  slug?: string | null;
}

export interface AdvancedOrgSearchResponsePagedData {
  list?: AdvancedOrgSearchResponse[];
  pagination?: Pagination;
}

export interface AgencyResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgentCoachResponse {
  agency?: AgencyResponse;
  agent?: AgentPersonResponse;
  coaches?: CoachResponse[] | null;
  noAgent: boolean;
  secondaryAgency?: AgencyResponse;
  secondaryAgent?: AgentPersonResponse;
}

export interface AgentContactInfo {
  agencyName?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface AgentPersonResponse {
  agency?: AgencyResponse;
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgentPlayerResponse {
  nilValue?: ValuationResponse;
  organization?: OrganizationResponse;
  player?: PlayerResponse;
}

export interface AgentPlayersResponse {
  agency?: AgencyResponse;
  agent?: AgentPersonResponse;
  noAgent: boolean;
  players?: AgentPlayerResponse[] | null;
  secondaryAgency?: AgencyResponse;
  secondaryAgent?: AgentPersonResponse;
}

export interface AgentResponse {
  company?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum AnnouncementImageGraphicType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  TopSchools = 'TopSchools',
  DecisionDate = 'DecisionDate',
  OfficialVisit = 'OfficialVisit',
  Offers = 'Offers',
}

export interface AppleShowMetaRes {
  url?: string | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum BestAvailableTransfersOrder {
  Default = 'Default',
  NilValue = 'NilValue',
  Industry = 'Industry',
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CityResponseRecord {
  country?: CountryResponseRecord;
  /** @format int32 */
  key: number;
  name?: string;
  state?: StateResponseRecord;
}

export enum ClassificationType {
  Unknown = 'Unknown',
  League = 'League',
  State = 'State',
  Conference = 'Conference',
  Division = 'Division',
  Association = 'Association',
  Region = 'Region',
  District = 'District',
  Class = 'Class',
  Metro = 'Metro',
  Section = 'Section',
}

export interface Coach {
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  slug?: string;
  type?: string;
}

export interface CoachResponse {
  /** @format int32 */
  age?: number | null;
  agency?: AgencyResponse;
  agent?: AgentPersonResponse;
  almaMater?: OrganizationResponse;
  /** @format int32 */
  almaMaterClassYear?: number | null;
  deceased: boolean;
  defaultAsset?: AssetResponse;
  defaultSport?: SportBase;
  degree?: string | null;
  description?: string | null;
  fired: boolean;
  firstName?: string;
  fullName?: string;
  highSchoolAlmaMater?: OrganizationResponse;
  /** @format int32 */
  highSchoolAlmaMaterClassYear?: number | null;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  noAgent: boolean;
  /** @format int32 */
  orgSeasonCount?: number | null;
  organization?: OrganizationResponse;
  primaryPosition?: AttributeResponse;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  secondaryAgency?: AgencyResponse;
  secondaryAgent?: AgentPersonResponse;
  secondaryPosition?: AttributeResponse;
  slug?: string;
  sports?: SportBase[];
  /** @format int32 */
  yearsActive?: number | null;
}

export interface CoachResponsePagedData {
  list?: CoachResponse[];
  pagination?: Pagination;
}

export interface CoachRosterItemResponse {
  coach?: CoachResponse;
  /** @format int64 */
  psoKey: number;
}

export interface CoachRosterItemResponseOrganizationRosterResponseRelatedPagedData {
  list?: CoachRosterItemResponse[];
  pagination?: Pagination;
  relatedModel?: OrganizationRosterResponse;
}

export interface CoachVisitedResponse {
  /** @format int64 */
  coachKey: number;
  coachName?: string;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  position?: PositionResponse;
  slug?: string;
}

export interface CoachingHistoryResponse {
  deceased: boolean;
  defaultSport?: SportBase;
  endOfTeam: boolean;
  fired: boolean;
  isPresent: boolean;
  /** @format int64 */
  latestPsoKey: number;
  /** @format int32 */
  latestYear: number;
  organization?: OrganizationResponse;
  position?: PositionResponse;
  promoted: boolean;
  resigned: boolean;
  sports?: SportBase[];
  /** @format int64 */
  startPsoKey: number;
  /** @format int32 */
  startYear: number;
}

export interface CoachingHistoryResponsePagedData {
  list?: CoachingHistoryResponse[];
  pagination?: Pagination;
}

export interface CoachingVisitResponse {
  coaches?: CoachVisitedResponse[];
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  dateOccurredTimestamp: number;
  /** @format int64 */
  key?: number | null;
}

export interface CollectiveGroupFounderResponse {
  /** @format int64 */
  collectiveGroupKey: number;
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  linkedInHandle?: string | null;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personSlug?: string | null;
  tikTokHandle?: string | null;
  title?: string | null;
  twitterHandle?: string | null;
}

export interface CollectiveGroupResponse {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders?: CollectiveGroupFounderResponse[];
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  /** @format date-time */
  launchDate?: string | null;
  linkedInHandle?: string | null;
  mergedIntoGroup?: CollectiveGroupSimpleResponse;
  /** @format int64 */
  mergedIntoGroupKey?: number | null;
  missionStatement?: string | null;
  /** @minLength 1 */
  name: string;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  /** @minLength 1 */
  slug: string;
  socialAsset?: ImageResponse;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports?: CollectiveGroupSportResponse[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface CollectiveGroupResponsePagedData {
  list?: CollectiveGroupResponse[];
  pagination?: Pagination;
}

export interface CollectiveGroupSimpleResponse {
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  /** @format int64 */
  key: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  slug: string;
}

export interface CollectiveGroupSportResponse {
  /** @minLength 1 */
  name: string;
  primary: boolean;
  /** @format int64 */
  sportKey: number;
}

export interface CommitResponse {
  commits?: Commits[];
  statusType: string;
}

export interface CommitResponsePaged {
  commits?: CommitsPagedData;
  statusType: string;
}

export interface Commits {
  /** @format int32 */
  count: number;
  /** @format int32 */
  order: number;
  players?: PlayerListBase[];
  type: string;
}

export enum CommitsFilterBase {
  Commits = 'Commits',
  Offers = 'Offers',
  Visits = 'Visits',
  Decommits = 'Decommits',
}

export interface CommitsPagedData {
  list?: Commits[];
  pagination?: Pagination;
}

export interface CompletedAnnouncementImage {
  completedImage?: FileResponse;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  requestKey: number;
  sHowOnProfileRequested: boolean;
}

export interface ConferenceFilter {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ContactInfo {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface CountryResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CurrentSportYearRecord {
  /** @format int32 */
  footballRecruitingYear: number;
  /** @format int32 */
  footballSeasonYear: number;
  /** @format int32 */
  highSchoolYear: number;
  /** @format int32 */
  nbaDraftYear: number;
  /** @format int32 */
  nflDraftYear: number;
  /** @format int32 */
  nilYear: number;
  /** @format int32 */
  nonFootballRecruitingYear: number;
  /** @format int32 */
  nonFootballSeasonYear: number;
  /** @format int32 */
  transferYear: number;
}

export interface DatabaseUpdateResponse {
  /** @format int64 */
  dateAdded: number;
  /** @format int64 */
  dateOccurred: number;
  /** @format int64 */
  key: number;
  link?: string | null;
  /** @format int64 */
  objectKey?: number | null;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  replacementText?: string | null;
  /** @format int32 */
  sportKey?: number | null;
  text?: string | null;
  /** Specifies types on a database update */
  type: DatabaseUpdateType;
}

export interface DatabaseUpdateResponsePagedData {
  list?: DatabaseUpdateResponse[];
  pagination?: Pagination;
}

/** Specifies types on a database update */
export enum DatabaseUpdateType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  OfficialVisit = 'OfficialVisit',
  UnofficialVisit = 'UnofficialVisit',
  VerifiedHeightOrWeight = 'VerifiedHeightOrWeight',
  RankingsMove = 'RankingsMove',
  HighSchoolTransfer = 'HighSchoolTransfer',
  Reclassification = 'Reclassification',
  NIL = 'NIL',
  CoachInHome = 'CoachInHome',
  TopSchools = 'TopSchools',
  NewVideo = 'NewVideo',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface DetailedOrganizationRes {
  abbreviation?: string | null;
  address?: string | null;
  /** @format int32 */
  addressCityKey?: number | null;
  alternateAsset?: AssetResponse;
  /** @format int64 */
  alternateAssetKey?: number | null;
  bio?: string | null;
  city?: string | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  isForeign?: boolean | null;
  isPrivate?: boolean | null;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  /** @format int32 */
  latitude?: number | null;
  lightAsset?: AssetResponse;
  /** @format int64 */
  lightAssetKey?: number | null;
  /** @format int32 */
  longitude?: number | null;
  mascot?: string | null;
  /** @format int32 */
  masseyKey?: number | null;
  name?: string;
  orgType: OrganizationType;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  slug?: string | null;
  socials?: OrganizationSocialResponse;
  telephone?: string | null;
  website?: string | null;
  /** @format int32 */
  zipCode?: number | null;
}

export interface DraftByOrgResponse {
  group?: string;
  players?: DraftPlayerResponse[];
}

export interface DraftByStarsResponse {
  /** @format double */
  blueChipPercent: number;
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  /** @format double */
  populationPercent: number;
  state?: StateResponseRecord;
  /** @format double */
  talentRatio: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  total: number;
  /** @format int32 */
  zeroStars: number;
}

export interface DraftByStarsResponsePagedData {
  list?: DraftByStarsResponse[];
  pagination?: Pagination;
}

export interface DraftByStarsSummaryItemResponse {
  /** @format double */
  draftRate: number;
  /** @format double */
  draftRatio: number;
  /** @format int32 */
  drafted: number;
  /** @format double */
  draftedPercent: number;
  /** @format int32 */
  recruited: number;
  /** @format double */
  recruitedPercent: number;
}

export interface DraftByStarsSummaryResponse {
  fiveStars?: DraftByStarsSummaryItemResponse;
  fourStars?: DraftByStarsSummaryItemResponse;
  threeStars?: DraftByStarsSummaryItemResponse;
  /** @format int32 */
  totalDrafted: number;
  /** @format int32 */
  totalRecruited: number;
  zeroStars?: DraftByStarsSummaryItemResponse;
}

export enum DraftFilterType {
  State = 'State',
  Position = 'Position',
  Stars = 'Stars',
  TopPicks = 'TopPicks',
}

export interface DraftPickStarsResponse {
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  total: number;
  /** @format int32 */
  zeroStars: number;
}

export interface DraftPickStateResponse {
  /** @format int32 */
  count: number;
  state?: StateResponseRecord;
}

export interface DraftPickStateResponsePagedData {
  list?: DraftPickStateResponse[];
  pagination?: Pagination;
}

export interface DraftPickYearResponse {
  /** @format int32 */
  count: number;
  /** @format int32 */
  year: number;
}

export interface DraftPickYearResponsePagedData {
  list?: DraftPickYearResponse[];
  pagination?: Pagination;
}

export interface DraftPlayerResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  nilValue?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  player?: PlayerResponse;
  rating?: RatingResponse;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  status?: RecStatusResponse;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export interface DraftSimpleResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export interface EpisodeMetaRes {
  imageSrc?: string | null;
  /** @format date-time */
  lastUpdated?: string | null;
}

export interface EpisodeRes {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  /** @format int32 */
  duration: number;
  /** @format uri */
  filePath: string;
  /** @format int64 */
  key: number;
  meta?: EpisodeMetaRes;
  name?: string;
  /** @format date-time */
  publishDate: string;
  /** @format int64 */
  showKey: number;
  type: EpisodeTypes;
  uid?: string;
}

export interface EpisodeResPagedData {
  list?: EpisodeRes[];
  pagination?: Pagination;
}

export enum EpisodeTypes {
  Unknown = 'Unknown',
  Podcast = 'Podcast',
  Video = 'Video',
}

export enum EventClassification {
  None = 'None',
  TrackAndField = 'TrackAndField',
  Combine = 'Combine',
}

export interface EventResponseRecord {
  city?: CityResponseRecord;
  /** @format int32 */
  cityKey?: number | null;
  classification: EventClassification;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface FileRes {
  domain?: string;
  fileName?: string;
  fullUrl?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  path?: string | null;
  tags?: FileTag[];
}

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export interface FileTag {
  key?: string;
  value?: string;
}

export enum Gender {
  Unknown = 'Unknown',
  Womens = 'Womens',
  Mens = 'Mens',
}

export interface IHeartRadioShowMetaRes {
  podcastId?: string | null;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface ImageResponsePagedData {
  list?: ImageResponse[];
  pagination?: Pagination;
}

export interface IndustryComparisonResponse {
  /** @format int64 */
  nilValue?: number | null;
  person?: PlayerResponse;
  rating?: RatingResponse;
  ratings?: PlayerRatingAllResponse[];
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
}

export interface IndustryComparisonResponsePagedData {
  list?: IndustryComparisonResponse[];
  pagination?: Pagination;
}

export interface Int32StringKeyValuePair {
  /** @format int32 */
  key: number;
  value?: string;
}

export interface JwPlayerShowMetaRes {
  playlistId?: string | null;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface KeyUrlSlug {
  fullName?: string;
  /** @format int64 */
  key: number;
  name?: string | null;
  slug?: string | null;
  url?: string | null;
}

export interface MeasurementComparisonRelatedResponse {
  /** @format int32 */
  numberOfYears: number;
  position?: PositionResponse;
}

/**
 * Place to store what type of record that a person achieved with a measurement.
 * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
 *   (i.e. - Personal Record, State Record, World Record)
 */
export enum MeasurementRecord {
  None = 'None',
  PersonalRecord = 'PersonalRecord',
  StateRecord = 'StateRecord',
  WorldRecord = 'WorldRecord',
}

export interface MeasurementTypeResponse {
  isTrackAndField: boolean;
  /** @format int64 */
  key: number;
  lowerIsBetter: boolean;
  /** @format int64 */
  measurementTypeKey: number;
  sort: SortMeasurementType;
  type: string | null;
  units: string | null;
}

export interface MegaphoneShowMetaRes {
  feedId?: string | null;
}

export interface NilCompanyResponse {
  assetUrl?: string | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilComplianceResponse {
  currentRules?: string | null;
  governingRuleLabel?: string | null;
  governingRuleUrl?: string | null;
  /** @format int64 */
  key: number;
  monetizationAllowed?: boolean | null;
  organizationType: OrganizationType;
  state?: StateResponseRecord;
  /** @format int32 */
  stateKey: number;
}

export interface NilComplianceResponsePagedData {
  list?: NilComplianceResponse[];
  pagination?: Pagination;
}

export interface NilHundredResponse {
  person: NilPlayerListResponse;
  valuation: PersonValuationResponse;
}

export interface NilPlayerListResponse {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  defaultSport?: SportBase;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  organizationLevel?: string | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingBase;
  slug?: string;
  sport?: SportBase;
  /** @format double */
  weight?: number | null;
}

export interface NilRankingResponse {
  person: PersonResponse;
  valuation: PersonValuationResponse;
}

export interface NilRankingResponseRelatedNilRankingResponseRelatedPagedData {
  list?: NilRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedNilRankingResponse;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Frozen = 'Frozen',
  Final = 'Final',
}

export interface OrgAutocompleteResponse {
  asset?: KeyUrlSlug;
  defaultAsset?: AssetResponse;
  /** @format int32 */
  key: number;
  organizationAssetUrl?: string | null;
  type?: string;
  value?: string;
}

export interface OrgAutocompleteResponsePagedData {
  list?: OrgAutocompleteResponse[];
  pagination?: Pagination;
}

export interface OrgDraftByStarsSummaryItemResponse {
  /** @format int32 */
  commitsDrafted: number;
  /** @format double */
  draftRate: number;
  /** @format double */
  draftRatio: number;
  /** @format int32 */
  drafted: number;
  /** @format double */
  draftedPercent: number;
  /** @format double */
  natAverageDraftedPercent: number;
  /** @format double */
  natDraftRate: number;
  /** @format double */
  natDraftedPercent: number;
  /** @format int32 */
  natTotalDrafted: number;
  /** @format int32 */
  recruited: number;
}

export interface OrgDraftByStarsSummaryResponse {
  /** @format int32 */
  draftRank: number;
  fiveStarSummary?: OrgDraftByStarsSummaryItemResponse;
  fourStarSummary?: OrgDraftByStarsSummaryItemResponse;
  /** @format int32 */
  natTotalDrafted: number;
  organization?: OrganizationResponse;
  overallStarSummary?: OrgDraftByStarsSummaryItemResponse;
  threeStarSummary?: OrgDraftByStarsSummaryItemResponse;
  /** @format int32 */
  totalDrafted: number;
}

export interface OrgTypeClassHierarchyRes {
  classificationType: ClassificationType;
  displayName: string;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name: string;
  organizationType: OrganizationType;
  /** @format int32 */
  parentOrgTypeClassHierarchyKey?: number | null;
  path?: string | null;
  /** @format int64 */
  relatedKey?: number | null;
  /** @format int32 */
  rootOrgTypeClassHierarchyKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export interface OrgTypeClassHierarchyResPagedData {
  list?: OrgTypeClassHierarchyRes[];
  pagination?: Pagination;
}

export enum OrgTypeClassHierarchyStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Removed = 'Removed',
}

export interface OrganizationBase {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface OrganizationBlueChipsRankingResponse {
  /** @format double */
  averageDistance?: number | null;
  /** @format double */
  blueChips?: number | null;
  /** @format int32 */
  conferenceRank?: number | null;
  /** @format double */
  inStateCount?: number | null;
  organization?: OrganizationBase;
  /** @format int32 */
  overallRank?: number | null;
  /** @format double */
  score?: number | null;
  /** @format double */
  socialNilValues?: number | null;
}

export interface OrganizationBlueChipsRankingResponseRankingResponseRelatedPagedData {
  list?: OrganizationBlueChipsRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RankingResponse;
}

export interface OrganizationDraftPickRankedResponse {
  /** @format int32 */
  firstRound: number;
  /** @format int32 */
  fourthThroughSeventhRound: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  rank: number;
  /** @format int32 */
  secondRound: number;
  /** @format int32 */
  thirdRound: number;
  /** @format int32 */
  total: number;
}

export interface OrganizationDraftPickRankedResponsePagedData {
  list?: OrganizationDraftPickRankedResponse[];
  pagination?: Pagination;
}

export interface OrganizationDraftRankingSummaryItemResponse {
  /** @format int32 */
  firstRoundPicks: number;
  /** @format int32 */
  firstRoundPicksConferenceRank?: number | null;
  /** @format int32 */
  firstRoundPicksRank?: number | null;
  /** @format int32 */
  firstThreeRoundPicks: number;
  /** @format int32 */
  firstThreeRoundPicksConferenceRank?: number | null;
  /** @format int32 */
  firstThreeRoundPicksRank?: number | null;
  name?: string;
  /** @format int32 */
  totalPicks: number;
  /** @format int32 */
  totalPicksConferenceRank?: number | null;
  /** @format int32 */
  totalPicksRank?: number | null;
}

export interface OrganizationDraftRankingSummaryResponse {
  conference?: ConferenceResponse;
  spanSummary?: OrganizationDraftRankingSummaryItemResponse;
  yearSummary?: OrganizationDraftRankingSummaryItemResponse;
}

export interface OrganizationDraftYearsResponse {
  /** @format double */
  blueChipPercent: number;
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  /** @format double */
  talentRatio: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  total: number;
  /** @format int32 */
  year: number;
  /** @format int32 */
  zeroStars: number;
}

export interface OrganizationDraftYearsResponsePagedData {
  list?: OrganizationDraftYearsResponse[];
  pagination?: Pagination;
}

export interface OrganizationRankingResponse {
  /** @format double */
  dispayOn3Score?: number | null;
  /** @format double */
  appliedAverageConsensusRating?: number | null;
  /** @format double */
  appliedAverageRating?: number | null;
  /** @format int32 */
  appliedCommits?: number | null;
  /** @format double */
  appliedTotalConsensusRating?: number | null;
  /** @format double */
  appliedTotalRating?: number | null;
  /** @format double */
  averageNilValue?: number | null;
  /** @format int32 */
  commits?: number | null;
  /** @format int32 */
  conferenceConsensusRank?: number | null;
  /** @format int32 */
  conferenceRank?: number | null;
  /** @format int32 */
  consensusFiveStars?: number | null;
  /** @format int32 */
  consensusFourStars?: number | null;
  /** @format int32 */
  consensusThreeStars?: number | null;
  /** @format double */
  deductions?: number | null;
  deductionsDescription?: string | null;
  /** @format double */
  dispayConsensusScore?: number | null;
  /** @format int32 */
  fiveStars?: number | null;
  /** @format int32 */
  fourStars?: number | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationBase;
  /** @format int32 */
  overallConsensusRank?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  threeStars?: number | null;
}

export interface OrganizationRankingResponseRankingResponseRelatedPagedData {
  list?: OrganizationRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RankingResponse;
}

export interface OrganizationRankingSummaryResponse {
  /** @format double */
  averageNilValue?: number | null;
  /** @format double */
  avgDistance?: number | null;
  /** @format double */
  blueChips?: number | null;
  classRatingChange?: Record<string, PlayerRatingChange>;
  /** @format double */
  classRatingCurrent?: number | null;
  /** @format double */
  classRatingPrevious?: number | null;
  conference?: string | null;
  conferenceConsensusRankChange?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  conferenceConsensusRankCurrent?: number | null;
  /** @format int32 */
  conferenceConsensusRankPrevious?: number | null;
  conferenceRankChange?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  conferenceRankCurrent?: number | null;
  /** @format int32 */
  conferenceRankPrevious?: number | null;
  directorPersonnel?: PersonBase;
  headCoach?: PersonBase;
  /** @format double */
  inState?: number | null;
  nationalRankChange?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  nationalRankCurrent?: number | null;
  /** @format int32 */
  nationalRankPrevious?: number | null;
  /** @format int32 */
  totalCommits?: number | null;
  /** @format int32 */
  year: number;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface OrganizationRosterResponse {
  orgResponse?: OrganizationResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  year: number;
}

export interface OrganizationSocialResponse {
  athleticsInstagram?: string | null;
  athleticsTwitter?: string | null;
  basketballInstagram?: string | null;
  basketballTwitter?: string | null;
  footballInstagram?: string | null;
  footballTwitter?: string | null;
  /** @format int32 */
  key: number;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonBase {
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  slug?: string;
}

export interface PersonConnectionFullResponse {
  connectedCollegeOrganization?: OrganizationResponse;
  connectedDraft?: DraftSimpleResponse;
  connectedPlayer?: SmallPlayerResponse;
  connectedRating?: RatingResponse;
  connectedRosterRating?: RatingResponse;
  connection?: PersonConnectionResponse;
}

export interface PersonConnectionFullResponsePagedData {
  list?: PersonConnectionFullResponse[];
  pagination?: Pagination;
}

export interface PersonConnectionResponse {
  /** @format int64 */
  connectedPersonKey: number;
  connectedPersonSport?: PersonSportResponseRecord;
  description?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface PersonEducationResponse {
  /** @format int32 */
  act?: number | null;
  /** @format double */
  gpa?: number | null;
  highSchoolTranscript?: FileRes;
  highSchoolTranscriptUrl?: string | null;
  intendedMajor?: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  sat?: number | null;
}

export interface PersonInfluencerResponse {
  handle?: string | null;
  handshake: boolean;
  type?: string | null;
}

export enum PersonMeasurementRequestType {
  Default = 'Default',
  TrackAndField = 'TrackAndField',
}

export interface PersonResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  defaultSport?: SportResponseRecord;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  /** The full name of the person */
  name?: string;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** The slug of the person */
  slug?: string;
  status?: RecruitmentStatusResponse;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export interface PersonSocialValuationResponse {
  /** @format int64 */
  followers?: number | null;
  /** @format int64 */
  previousValuation?: number | null;
  profile?: PersonInfluencerResponse;
  socialType: SocialType;
  /** @format int64 */
  valuation?: number | null;
  /** @format int64 */
  valuationChange?: number | null;
}

export interface PersonSportBase {
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface PersonSportOrganizationResponse {
  /** @format int32 */
  age?: number | null;
  classRank?: string | null;
  /** @format int32 */
  expMax?: number | null;
  /** @format int32 */
  expMin?: number | null;
  /** @format int32 */
  jerseyNumber?: number | null;
  organization?: OrganizationResponse;
  positionAbbr?: string | null;
  rating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
}

export interface PersonSportResponseRecord {
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export enum PersonStatusReviewStatus {
  Unreviewed = 'Unreviewed',
  Reviewed = 'Reviewed',
}

export enum PersonStatusTier {
  Base = 'Base',
  Emerging = 'Emerging',
  Star = 'Star',
  Unknown = 'Unknown',
  Elite = 'Elite',
}

export enum PersonStatusVisibility {
  None = 'None',
  PublicWithArticleTaggingDisabled = 'PublicWithArticleTaggingDisabled',
  PublicWithArticleTaggingEnabled = 'PublicWithArticleTaggingEnabled',
}

export interface PersonValuationResponse {
  /** @format int64 */
  followers?: number | null;
  groupName?: string | null;
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  lastUpdated?: number | null;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  rosterValue?: number | null;
  socialValuations?: PersonSocialValuationResponse[];
  /** @format int64 */
  valuation?: number | null;
  /** @format int64 */
  valuationChange?: number | null;
}

export interface PersonWithSocial {
  /** @format int32 */
  classYear: number;
  espnProfile?: string | null;
  firstName?: string;
  instagramProfile?: string | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  rivalsProfile?: string | null;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  twoFourSevenProfile?: string | null;
}

export interface PlayerListBase {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingBase;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingBase;
  slug?: string;
  sport?: SportBase;
  /** @format double */
  weight?: number | null;
}

export interface PlayerListBasePagedData {
  list?: PlayerListBase[];
  pagination?: Pagination;
}

export interface PlayerOrgResponse {
  draft?: DraftSimpleResponse;
  organizations?: PersonSportOrganizationResponse[] | null;
}

export enum PlayerProfileBadge {
  None = 'None',
  Emerging1 = 'Emerging_1',
  Emerging2 = 'Emerging_2',
  Emerging3 = 'Emerging_3',
  Emerging4 = 'Emerging_4',
  Star3 = 'Star_3',
  Star4 = 'Star_4',
  Star5 = 'Star_5',
  College = 'College',
  Pro = 'Pro',
  HOF = 'HOF',
}

export interface PlayerProfileRequest {
  agentInfo?: AgentContactInfo;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  classYear: number;
  /** @minLength 1 */
  email: string;
  /**
   * @minLength 1
   * @maxLength 45
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  highSchoolName?: string;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  /**
   * @minLength 1
   * @maxLength 45
   */
  lastName: string;
  ncaaId?: string | null;
  parentInfo?: ContactInfo;
  /** @minLength 1 */
  phoneNumber: string;
  secondaryParentInfo?: ContactInfo;
  /** @minItems 1 */
  sports: PlayerProfileSportRequest[];
}

export interface PlayerProfileResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  badge: PlayerProfileBadge;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  classRank?: string | null;
  /** @format int32 */
  classYear: number;
  /** @format int64 */
  collegeOrgKey?: number | null;
  currentState?: StateResponse;
  defaultAsset?: ImageResponse;
  defaultSport?: SportBase;
  degree?: string | null;
  description?: string | null;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  /** @format int64 */
  highSchoolOrgKey?: number | null;
  hometownName?: string | null;
  hometownState?: StateResponse;
  isAthlete: boolean;
  isCoach: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  juniorCollegeOrgKey?: number | null;
  /** @format int64 */
  key: number;
  /** If the profile is managed by a Recruit+ user instead of On3. */
  managedByUser: boolean;
  name?: string;
  ncaaId?: string | null;
  /** @format double */
  nilValue?: number | null;
  /** @format int64 */
  oracleKey?: number | null;
  organizationLevel?: string | null;
  /** @format int64 */
  personSportKey?: number | null;
  playerStatus?: Status;
  positionAbbreviation?: string | null;
  /** @format int64 */
  prepSchoolOrgKey?: number | null;
  primaryPosition?: PositionResponse;
  prospectVerified: boolean;
  ranking?: RatingBase;
  /** @format int64 */
  rankingKey?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  reviewStatus: PersonStatusReviewStatus;
  slug?: string;
  sports?: SportBase[];
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
  /** @format double */
  weight?: number | null;
}

export interface PlayerProfileResponsePagedData {
  list?: PlayerProfileResponse[];
  pagination?: Pagination;
}

export interface PlayerProfileSportRequest {
  isPrimary: boolean;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  primaryPositionKey: number;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
}

export type PlayerRatingAllAndPersonResponse = object;

export interface PlayerRatingAllAndPersonResponsePagedData {
  list?: PlayerRatingAllAndPersonResponse[];
  pagination?: Pagination;
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PlayerRatingResponse {
  change?: Record<string, PlayerRatingChange>;
  consensusChange?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  ranking: PublicRankingResponse;
  /** @format int32 */
  rankingKey: number;
  rankingType: RankingType;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  strength: number;
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PlayerTransfer {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  committedArticle?: SimpleHeadlineResponse;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  eligibility?: TransferEligibility;
  enteredArticle?: SimpleHeadlineResponse;
  exitedArticle?: SimpleHeadlineResponse;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int32 */
  index: number;
  industryComparison?: PlayerRatingAllResponse[];
  interestStatus?: Status;
  /** @format int64 */
  key: number;
  lastTeam?: Team;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  recStatus: RecruitmentStatus;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingExtendedResponse;
  slug?: string;
  sport?: SportBase;
  transferIndustryComparison?: PlayerRatingAllResponse[];
  transferRating?: RatingExtendedResponse;
  valuation?: ValuationResponse;
  /** @format double */
  weight?: number | null;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
}

export interface PlayerTransferRelatedTransferResponseRelatedPagedData {
  list?: PlayerTransfer[];
  pagination?: Pagination;
  relatedModel?: RelatedTransferResponse;
}

export interface PlayerVisitRes {
  coaches: CoachVisitedResponse[] | null;
  /** @format int64 */
  dateOccurred: number;
  /** @format double */
  distance: number | null;
  organization: OrganizationResponse;
  type: string;
}

export interface PlayerVisitsCountRes {
  /** @format int32 */
  coachCount: number;
  /** @format int32 */
  officialCount: number;
  /** @format int32 */
  total: number;
  /** @format int32 */
  unofficialCount: number;
}

export interface PlayerVisitsOrgCountRes {
  /** @format int32 */
  coachCount: number;
  /** @format int32 */
  officialCount: number;
  organization: OrganizationResponse;
  /** @format int32 */
  total: number;
  /** @format int32 */
  unofficialCount: number;
}

export interface PlayerVisitsRes {
  counts: PlayerVisitsCountRes;
  organizationCounts: PlayerVisitsOrgCountRes[];
  visits: PlayerVisitRes[];
}

export interface PositionBase {
  abbr?: string | null;
  /** @format int32 */
  key: number;
  name?: string | null;
}

export interface PositionFilter {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface PredictionAccuracies {
  all?: PredictionAccuracy;
  year?: PredictionAccuracy;
}

export interface PredictionAccuracy {
  /** @format double */
  averageDaysCorrect?: number | null;
  /** @format double */
  correct: number;
  /** @format double */
  percentCorrect?: number | null;
  /** @format double */
  total: number;
}

export interface ProfileOrgResponse {
  /** @format int32 */
  age?: number | null;
  /** @format int32 */
  expMax?: number | null;
  /** @format int32 */
  expMin?: number | null;
  organization?: Team;
  positionAbbr?: string | null;
  rating?: RatingBase;
  year?: string | null;
}

export interface ProfileRecruitResponse {
  /** @format int32 */
  classYear: number;
  committedStatus?: Status;
  highSchool?: string | null;
  highSchoolOrg?: OrganizationResponse;
  homeTown?: string | null;
  rating?: RatingBase;
}

export interface PublicCombineMeasurementResponseRecord {
  isVerified: boolean;
  measurementType?: MeasurementTypeResponse;
  /** @format int64 */
  measurementTypeKey: number;
  /** @format double */
  value?: number | null;
}

export interface PublicMeasurementAveragesResponseRecord {
  /** @format double */
  top300Average?: number | null;
  /** @format double */
  top300Difference?: number | null;
  /** @format double */
  combineDraftedAverage?: number | null;
  /** @format double */
  combineDraftedDifference?: number | null;
  currentMeasurementVerified?: boolean | null;
  /** @format double */
  currentPersonMeasurement?: number | null;
  /** @format int64 */
  measurementKey: number;
  measurementName?: string;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  measurementRecord?: MeasurementRecord;
  sort: SortMeasurementType;
}

export interface PublicMeasurementAveragesResponseRecordMeasurementComparisonRelatedResponseRelatedPagedData {
  list?: PublicMeasurementAveragesResponseRecord[];
  pagination?: Pagination;
  relatedModel?: MeasurementComparisonRelatedResponse;
}

export interface PublicMeasurementPersonResponseRecord {
  playerMeasurements?: PublicMeasurementResponseRecord[] | null;
}

export interface PublicMeasurementResponseRecord {
  /** @format double */
  top300Average?: number | null;
  /** @format double */
  ageMeasurementOccurred?: number | null;
  /** @format int32 */
  dateAdded: number;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  dateOccurred?: number | null;
  /** @format double */
  delta?: number | null;
  /** @format double */
  draftChangePercent?: number | null;
  /** @format double */
  draftedAverage?: number | null;
  elite: boolean;
  event?: EventResponseRecord;
  /** @format int64 */
  eventKey?: number | null;
  eventName?: string | null;
  isCurrent: boolean;
  /** @format int64 */
  key: number;
  measurementType?: MeasurementTypeResponse;
  /** @format int64 */
  measurementTypeKey: number;
  organization?: OrganizationResponse;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  personSportOrgKey?: number | null;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  record: MeasurementRecord;
  /** @format double */
  topAverageChangePercent?: number | null;
  type?: string;
  /** @format double */
  value?: number | null;
  verified: boolean;
  /** @format int64 */
  verifiedByUserKey?: number | null;
}

export interface PublicOrganizationSearchResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  city?: CityResponseRecord;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  lcos?: SimpleLcoRes[] | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  sites?: SimpleSiteResponseRecord[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface PublicOrganizationSearchResponsePagedData {
  list?: PublicOrganizationSearchResponse[];
  pagination?: Pagination;
}

export interface PublicPersonContactInfoResponse {
  email?: StringPersonContactInfoMetaResponse;
  instagramHandle?: StringPersonContactInfoMetaResponse;
  phoneNumber?: StringPersonContactInfoMetaResponse;
  tikTokHandle?: StringPersonContactInfoMetaResponse;
  twitterHandle?: StringPersonContactInfoMetaResponse;
}

export interface PublicPlayerDealResponse {
  agent?: AgentResponse;
  /** @format double */
  amount?: number | null;
  collectiveGroup?: CollectiveGroupSimpleResponse;
  company?: NilCompanyResponse;
  /** @format date-time */
  date?: string | null;
  detail?: SimpleHeadlineResponse;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  person?: PlayerResponse;
  rating?: RatingResponse;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  sourceUrl?: string | null;
  status?: RecStatusResponse;
  verified: boolean;
}

export interface PublicPlayerDealResponseCollectiveGroupResponseRelatedPagedData {
  list?: PublicPlayerDealResponse[];
  pagination?: Pagination;
  relatedModel?: CollectiveGroupResponse;
}

export interface PublicPlayerDealResponsePagedData {
  list?: PublicPlayerDealResponse[];
  pagination?: Pagination;
}

export interface PublicRankingResponse {
  /** @format int32 */
  key: number;
  sport: SportBase;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

/** Specifies the caegories quotes can be. */
export enum QuoteCategory {
  Inspirational = 'Inspirational',
  Motivational = 'Motivational',
  Funny = 'Funny',
  Leadership = 'Leadership',
  Success = 'Success',
}

export interface QuoteResponse {
  body?: string;
  /** Specifies the caegories quotes can be. */
  category: QuoteCategory;
  /** @format date-time */
  dateAdded: string;
  /** @format date-time */
  dateUpdated: string;
  /** @format int64 */
  key: number;
  person?: PersonWithSocial;
  /** @format int64 */
  personKey?: number | null;
}

export interface QuoteResponsePagedData {
  list?: QuoteResponse[];
  pagination?: Pagination;
}

export interface RankedOrganizationsDraftResponse {
  /** @format double */
  draftRate: number;
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  organization?: OrganizationResponse;
  /** @format double */
  percentDrafted: number;
  /** @format int32 */
  rank: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  total: number;
}

export interface RankedOrganizationsDraftResponsePagedData {
  list?: RankedOrganizationsDraftResponse[];
  pagination?: Pagination;
}

export interface RankingResponse {
  /** @format double */
  on3Percent?: number | null;
  autoPublish: boolean;
  /** @format int32 */
  averageCommits: number;
  createVersion: boolean;
  /** @format int32 */
  dateCreated: number;
  /** @format int32 */
  dateScheduled?: number | null;
  /** @format int32 */
  dateUpdated?: number | null;
  /** @format int32 */
  draftRankStrength: number;
  /** @format double */
  espnPercent?: number | null;
  isLegacy: boolean;
  isLocked: boolean;
  /** @format int32 */
  key: number;
  organizationType: OrganizationType;
  /** @format int32 */
  rankStrength: number;
  /** @format double */
  rivalsPercent?: number | null;
  sport?: SportBase;
  /** @format int32 */
  sportKey: number;
  staleness: RankingStaleness;
  status: RankingStatus;
  /** @format double */
  tfsSportsPercent?: number | null;
  type: RankingType;
  /** @format int32 */
  year: number;
}

export enum RankingStaleness {
  None = 'None',
  On300 = 'On300',
  Consensus = 'Consensus',
}

export enum RankingStatus {
  Draft = 'Draft',
  Processing = 'Processing',
  Ready = 'Ready',
  Scheduled = 'Scheduled',
  Publishing = 'Publishing',
  Published = 'Published',
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RatingBase {
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingExtendedResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface RdbV1CoachesAgentsDetailParams {
  /** @format int64 */
  coachKey: number;
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 3
   */
  limit?: number;
}

export interface RdbV1CoachesHistoryDetailParams {
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1CoachesProfileDetailParams {
  /**
   * The coach key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1CoachesSalariesListParams {
  /** @format int64 */
  agencyKey?: number;
  /** @format int64 */
  agentKey?: number;
  conferenceAbbr?: string;
  direction?: SortDirection;
  /** @format int32 */
  limit?: number;
  orderBy?: SortByCoachColumn;
  /** @format int32 */
  page?: number;
  positionAbbr?: string;
  /** @format int64 */
  secondaryAgencyKey?: number;
  /** @format int64 */
  secondaryAgentKey?: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV1CoachesSlimProfileDetailParams {
  /**
   * The coach key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1CollectiveGroupsDealsDetailParams {
  /**
   * The collective group key for the
   * @format int64
   */
  key: number;
  /**
   * The number of deals per page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface RdbV1CollectiveGroupsListParams {
  conference?: string;
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1CommitsLatestListParams {
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  /** @format int32 */
  orgKey?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  sport?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV1CommitsOrganizationsDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /** The status */
  status?: CommitsFilterBase;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV1CommitsOrganizationsLatestCommitsDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface RdbV1DefaultYearUpdateParams {
  typeToUpdate?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1DraftOrganizationRankListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1DraftPickOrganizationRankListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1DraftsByStarsListParams {
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1999
   * @max 4294967295
   */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1DraftsByStarsSummaryListParams {
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1999
   * @max 4294967295
   */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1DraftsListParams {
  /**
   * filter by round
   * @format int32
   */
  round?: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
  /**
   * filter by year
   * @format int32
   */
  year?: number;
}

export interface RdbV1DraftsPlayersDetailParams {
  filterType: DraftFilterType;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  positionKey?: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1FiltersConferencesListParams {
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV1FiltersDraftRoundsListParams {
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV1FiltersPositionsListParams {
  positionType?: PositionType;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1FiltersTeamsListParams {
  /** bool for grouping by conference */
  groupBy?: boolean;
  /**
   * The option sport key to select teams for. Defaults to Football
   * @format int32
   */
  sportKey?: number;
  /**
   * The optional year to select teams for. Defaults to the current year
   * @format int32
   */
  year?: number;
}

export interface RdbV1Nil100ListParams {
  /** @format int32 */
  year?: number;
}

export interface RdbV1NilCompliancesListParams {
  organizationType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
}

export interface RdbV1NilCompliancesStateListParams {
  abr: string;
}

export interface RdbV1NilRankingsListParams {
  gender?: Gender;
  /** @format int32 */
  orgKey?: number;
  orgType?: OrganizationType;
  positionAbbr?: string;
  /** @format int32 */
  sportKey?: number;
  stateAbbr?: string;
  transferPortal?: boolean;
  /** @format int32 */
  year?: number;
}

export interface RdbV1OrganizationsAdvancedSearchListParams {
  conferenceKeys?: number[];
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit?: number;
  organizationKeys?: number[];
  organizationType?: OrganizationType;
  organizationTypes?: OrganizationType[];
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  searchText?: string;
  sportKeys?: number[];
  /** @format int32 */
  stateKey?: number;
}

export interface RdbV1OrganizationsAutocompletePaginatedListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  term?: string;
  type?: OrganizationType[];
}

export interface RdbV1OrganizationsCoachRosterDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface RdbV1OrganizationsDraftClassByStateDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RdbV1OrganizationsDraftClassByYearDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RdbV1OrganizationsDraftCountByStarsDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RdbV1OrganizationsDraftCountByYearDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RdbV1OrganizationsDraftRankingSummaryDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1OrganizationsDraftedPlayersDetailParams {
  filterType: DraftFilterType;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  positionKey?: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1OrganizationsDraftsByStarsSummaryDetailParams {
  /** @format int64 */
  orgKey: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1999
   * @max 4294967295
   */
  year: number;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  yearSpan?: number;
}

export interface RdbV1OrganizationsOrgTypeClassHierarchiesDetailParams {
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface RdbV1OrganizationsRosterDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface RdbV1OrganizationsRosterHeaderDetailParams {
  /**
   * The organization key
   * @format int32
   */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface RdbV1OrganizationsSearchListParams {
  address?: string;
  /** @format int32 */
  addressCityKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  mascot?: string;
  name?: string;
  orgType?: OrganizationType;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  stateKey?: number;
}

export interface RdbV1PeopleDatabaseUpdatesDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  personKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey?: number;
}

export interface RdbV1PeopleImagesDetailParams {
  /**
   * the page of items that you want
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PeopleMeasurementsAveragesDetailParams {
  measurementRequestType?: PersonMeasurementRequestType;
  /** @format int64 */
  personKey: number;
  /**
   * @format int32
   * @default 3
   */
  yearsToCalucate?: number;
}

export interface RdbV1PeopleMeasurementsDetailParams {
  measurementRequestType?: PersonMeasurementRequestType;
  /** @format int64 */
  personKey: number;
}

export interface RdbV1PeoplePersonConnectionsDetailParams {
  /**
   * The pagination limit per page- Default: 25
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The pagination page desired-Default: 1
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The Person key
   * @format int64
   */
  personKey: number;
}

export interface RdbV1PeopleTrackAndFieldMeasurementsDetailParams {
  /**
   * @format int32
   * @default 5
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface RdbV1PeopleValuationGrowthDetailParams {
  /** @default false */
  endOfMonthOnly?: boolean;
  /**
   * @format int32
   * @default 10
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface RdbV1PersonRecruitmentEvaluationsDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
}

export interface RdbV1PersonVideosDetailParams {
  /** filter by category */
  category?: Category;
  /**
   * the page of items that you want
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PlayerAllRankingsDetailParams {
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1PlayerDatabaseUpdatesDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  personKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey?: number;
}

export interface RdbV1PlayerImagesDetailParams {
  /**
   * the page of items that you want
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PlayerLookUpMappingListParams {
  /** @format uuid */
  id: string;
}

export interface RdbV1PlayerOrganizationsDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The player key
   * @format int64
   */
  playerKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PlayerProfileDetailParams {
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1PlayerRankingsDetailParams {
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1PlayerTeamTargetsDetailParams {
  /**
   * Page to return, limit is default of 25
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  playerKey: number;
  /** @format int32 */
  recruitmentKey?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1PlayerVerifiedListParams {
  /**
   * Page to return, limit is default of 25
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface RdbV1PlayerVerifyCreateParams {
  /** @format int64 */
  personKey: number;
  /** @default false */
  sendJwt?: boolean;
  verificationCode?: string;
}

export interface RdbV1PlayerVideosDetailParams {
  /** filter by category */
  category?: Category;
  /**
   * the page of items that you want
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PlayerVideosHighlightsDetailParams {
  /**
   * the page of items that you want
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /**
   * filter by sport
   * @format int32
   */
  sportKey?: number;
}

export interface RdbV1PlayerVisitCenterDetailParams {
  /**
   * The player key
   * @format int64
   */
  playerKey: number;
  /** @format int32 */
  sportKey?: number;
  /**
   * False to only show top 5
   * @default false
   */
  viewAll?: boolean;
}

export interface RdbV1PlayerVisitsDetailParams {
  /** @format int64 */
  playerKey: number;
  /** @format int32 */
  recruitmentKey?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV1PlayersIndustryComparisionListListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  positionAbbr?: string;
  sortByIndustry?: SortByIndustry;
  /** @format int32 */
  sportKey?: number;
  stateAbbr?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1PlayersIndustryComparisionListParams {
  /**
   * Optional Pagination page default 1
   * @format int32
   * @default 1
   */
  page?: number;
  /** OptionalPosition abbreviation year */
  positionAbbr?: string;
  /** Optional parameter for sorting (will default to On3 Consensus) */
  sortByIndustry?: SortByIndustry;
  /**
   * Player sport key
   * @format int32
   */
  sportKey?: number;
  /** Optional State abbreviation */
  stateAbbr?: string;
  /**
   * Recruitment year
   * @format int32
   */
  year?: number;
}

export interface RdbV1PredictionsDetailParams {
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  userKey: number;
}

export interface RdbV1QuotesListParams {
  /** The optional category of the quotes desired */
  category?: QuoteCategory;
  /**
   * The pagination limit per page- Default: 25
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The pagination page desired-Default: 1
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The optional key of the person for whom the quote belongs
   * @format int32
   */
  personKey?: number;
}

export interface RdbV1RecruitmentRecruitmentEvaluationsDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * page data
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The recruitment key
   * @format int64
   */
  recruitmentKey: number;
}

export interface RdbV1RecruitmentsLatestRpmPicksListParams {
  /**
   * The Optional Amount of items to return: default is 50
   * @format int32
   */
  limit?: number;
  /**
   * The Optional Recruitment Oracle Pick Team Orginization Key to filter to
   * @format int32
   */
  orgKey?: number;
  /**
   * The Optional Page to return: default is 1
   * @format int32
   */
  page?: number;
  /**
   * The Optional Sport Key to filter to
   * @format int32
   */
  sportKey?: number;
  /**
   * The Optional Recruitment year to filter to
   * @format int32
   */
  year?: number;
  /** The Optional way to Sort the RPM Pick List: default is descending by Recruitment Oracle Date Added */
  yearSort?: SortDirection;
}

export interface RdbV1TeamRankingBluechipsTeamRankingsDetailParams {
  /** Optional Filter by conference abbreviation */
  conferenceAbbr?: string;
  /** Optional Filter for consesus, default is true */
  consensus?: boolean;
  /** Optional sorting direction, default is Ascending */
  direction?: SortDirection;
  /**
   * Optional items per page, default is 50
   * @format int32
   */
  limit?: number;
  /** Optional sorting field, default is overall-rank */
  orderBy?: string;
  /**
   * Optional Page to return, default is 1
   * @format int32
   */
  page?: number;
  /**
   * The sport key
   * @format int32
   */
  sport: number;
  /**
   * The 4 digit year
   * @format int32
   */
  year: number;
}

export interface RdbV1TeamRankingConsensusTeamRankingsDetailParams {
  /** Filter by conference */
  conferenceAbbr?: string;
  /**
   * Optional items per page, default is 50
   * @format int32
   * @min 1
   * @max 2147483647
   */
  limit?: number;
  /**
   * Page to return, limit is 50
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** Type of sort requested (defaults to Score) */
  sort?: TeamRankingSort;
  /** The sport name */
  sport: string;
  /**
   * The 4 digit year
   * @format int32
   */
  year: number;
}

export interface RdbV1TeamRankingOrganizationsSummaryDetailParams {
  /**
   * The organization key
   * @format int32
   */
  organizationKey: number;
  /**
   * The sport's key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year to filter down to
   * @format int32
   */
  year?: number;
}

export interface RdbV1TeamRankingTeamRankingsDetailParams {
  /** Filter by conference */
  conferenceAbbr?: string;
  /**
   * Optional items per page, default is 50
   * @format int32
   * @min 1
   * @max 2147483647
   */
  limit?: number;
  /**
   * Page to return, limit is 50
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** Type of sort requested (defaults to Score) */
  sort?: TeamRankingSort;
  /** The sport name */
  sport: string;
  /**
   * The 4 digit year
   * @format int32
   */
  year: number;
}

export interface RdbV1TransfersBestAvailableListParams {
  /**
   * The total number of transfers to include in the list. If `OrderBy` is `Default`, then this is 300.
   * @format int32
   */
  cutoff?: number;
  orderBy?: BestAvailableTransfersOrder;
  /** @format int32 */
  orgKey?: number;
  /** @format int32 */
  page?: number;
  positionAbbr?: string;
  /** @format int32 */
  sportKey?: number;
  status?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1TransfersLatestListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  orgKey?: number;
  /** @format int32 */
  page?: number;
  positionAbbr?: string;
  /** @format int32 */
  sportKey?: number;
  status?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1TrendingListsListParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  siteKey?: number;
  /** @format int64 */
  trendingListKey?: number;
}

export interface RecStatusResponse {
  committedAsset?: OrganizationResponse;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  draft: boolean;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  /** @format date-time */
  transferWithdrawn?: string | null;
  transferredAsset?: OrganizationResponse;
  type?: string;
}

export interface RecruitmentEvaluationResponse {
  /** @format int64 */
  authorKey: number;
  authorName?: string | null;
  authorTitle?: string | null;
  body: string;
  category?: ScoutingCategory;
  /** @format date-time */
  dateAdded: string;
  /** @format date-time */
  dateUpdated: string;
  /** @format int32 */
  dateUpdatedUnix: number;
  /** @format int64 */
  key: number;
  premium: boolean;
  primary: boolean;
  /** @format int64 */
  recruitmentKey: number;
  title: string;
}

export interface RecruitmentEvaluationResponsePagedData {
  list?: RecruitmentEvaluationResponse[];
  pagination?: Pagination;
}

export enum RecruitmentStatus {
  None = 'None',
  Decommitted = 'Decommitted',
  Committed = 'Committed',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface RecruitmentStatusResponse {
  /** @format date-time */
  commitmentDate?: string | null;
  committedOrganizationAsset?: ImageResponse;
  committedOrganizationAssetUrl?: string | null;
  /** @format int64 */
  committedOrganizationKey?: number | null;
  committedOrganizationPrimaryColor?: string | null;
  committedOrganizationSlug?: string | null;
  /** @format int32 */
  highestInterestLevel?: number | null;
  /** @format int32 */
  interestCount: number;
  isCommitted: boolean;
  isEnrolled: boolean;
  isSigned: boolean;
  isTransfer: boolean;
  /** @format int32 */
  recruitmentYear: number;
  shortTermSignee: boolean;
  sportName?: string | null;
  transferredFromOrganizationAssetUrl?: string | null;
  transferredFromOrganizationSlug?: string | null;
}

export interface RelatedNilRankingResponse {
  gender?: Gender;
  /** @format date-time */
  lastUpdated: string;
  organization?: SimpleOrganizationResponse;
  organizationType?: OrganizationType;
  position?: PositionResponse;
  sport?: SportBase;
  state?: StateResponse;
  transferPortal?: boolean | null;
  /** @format int32 */
  year: number;
}

export interface RelatedTransferResponse {
  org?: OrganizationBase;
  /** @format int32 */
  playersCommittedCount: number;
  /** @format double */
  playersCommittedPercentage: number;
  /** @format int32 */
  playersEnteredCount: number;
  /** @format int32 */
  playersWithdrawnCount: number;
  /** @format double */
  playersWithdrawnPercentage: number;
  position?: PositionBase;
  sport?: SportBase;
  status?: string;
  type?: string;
  /** @format int32 */
  year?: number | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RosterHeaderResponse {
  /** @format double */
  averageNilValue?: number | null;
  /** @format double */
  averageRating?: number | null;
  /** @format int32 */
  conferenceRank?: number | null;
  headCoach?: SimpleCoachResponse;
  /** @format double */
  prevAverageRating?: number | null;
  /** @format int32 */
  prevConferenceRank?: number | null;
  /** @format int32 */
  prevTalentRank?: number | null;
  /** @format int32 */
  talentRank?: number | null;
  /** @format double */
  totalNilValue?: number | null;
}

export interface RosterItemResponse {
  industryComparison?: PlayerRatingAllResponse[];
  nilValue?: ValuationResponse;
  organization?: OrganizationResponse;
  player?: PlayerResponse;
  /** @format int64 */
  psoKey: number;
  rating?: RatingResponse;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
}

export interface RosterItemResponseOrganizationRosterResponseRelatedPagedData {
  list?: RosterItemResponse[];
  pagination?: Pagination;
  relatedModel?: OrganizationRosterResponse;
}

export interface RpmPercentResponse {
  /** @format double */
  distance?: number | null;
  organization?: OrganizationResponse;
  /** @format double */
  percent?: number | null;
  showRpmPercentage?: boolean | null;
}

export interface RpmPickList {
  pick?: RpmPicks;
  player?: PlayerListBase;
}

export interface RpmPickListPredictionAccuraciesRelatedPagedData {
  list?: RpmPickList[];
  pagination?: Pagination;
  relatedModel?: PredictionAccuracies;
}

export interface RpmPickListUserProfileResponseRecordRelatedPagedData {
  list?: RpmPickList[];
  pagination?: Pagination;
  relatedModel?: UserProfileResponseRecord;
}

export interface RpmPicks {
  articleLink?: string | null;
  /** @format double */
  confidence?: number | null;
  correct?: boolean | null;
  /** @format date-time */
  dateAdded: string;
  /** @format double */
  daysCorrect?: number | null;
  description?: string;
  expert?: UserProfileBase;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  premium: boolean;
  /** @format double */
  previousConfidence?: number | null;
  /** @format date-time */
  previousDateAdded?: string | null;
  topTeams?: RpmSummaryChangeResponse[];
  type: RpmType;
  /** @format int32 */
  year: number;
}

export interface RpmResponse {
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface RpmSummaryChangeResponse {
  /** @format double */
  currentPercentage?: number | null;
  /** @format int32 */
  currentRank?: number | null;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  previousPercentage?: number | null;
  /** @format int32 */
  previousRank?: number | null;
}

export interface RpmSummaryResponse {
  locked: boolean;
  predictions?: RpmResponse[];
}

export enum RpmType {
  Pick = 'Pick',
  ConfidenceChange = 'ConfidenceChange',
  NewLeader = 'NewLeader',
  PercentChange = 'PercentChange',
}

export enum ScoutingCategory {
  Camp = 'Camp',
  Game = 'Game',
  SevenVsSeven = 'SevenVsSeven',
  AllAmericanPractice = 'AllAmericanPractice',
  Summary = 'Summary',
  RankingEvaluation = 'RankingEvaluation',
}

export interface SecondaryPersonSportResponse {
  positions?: string[] | null;
  /** @format int64 */
  sportKey: number;
  sportName?: string;
  years?: number[] | null;
}

export interface SecondaryPersonSportResponsePagedData {
  list?: SecondaryPersonSportResponse[];
  pagination?: Pagination;
}

export interface ShowRes {
  appleMeta?: AppleShowMetaRes;
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  external: boolean;
  featured: boolean;
  hosts?: SimpleUserResponse[];
  iHeartRadioMeta?: IHeartRadioShowMetaRes;
  jwPlayerMeta?: JwPlayerShowMetaRes;
  /** @format int64 */
  key: number;
  megaphoneMeta?: MegaphoneShowMetaRes;
  name?: string;
  national: boolean;
  /** @format int32 */
  order: number;
  shortName?: string | null;
  simplecastMeta?: SimplecastShowMetaRes;
  site?: SimpleSiteResponseRecord;
  /** @format int32 */
  siteKey?: number | null;
  spotifyMeta?: SpotifyShowMetaRes;
  youTubeMeta?: YouTubeShowMetaRes;
}

export interface SimpleCoachResponse {
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  /** @format int32 */
  orgSeasonCount?: number | null;
  organization?: OrganizationResponse;
  primaryPosition?: AttributeResponse;
  secondaryPosition?: AttributeResponse;
  slug?: string;
  /** @format int32 */
  yearsActive?: number | null;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimpleImageResponseRecord {
  /** @format int64 */
  key: number;
  url?: string | null;
}

export interface SimpleLcoRes {
  /** @format int32 */
  conferenceKey: number;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueConferenceKey: number;
  /** @format int64 */
  leagueKey: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface SimpleOrganizationResponse {
  abbreviation?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  organizationType: OrganizationType;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface SimpleShowRes {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  external: boolean;
  featured: boolean;
  /** @format int64 */
  key: number;
  name?: string;
  national: boolean;
  /** @format int32 */
  order: number;
  shortName?: string | null;
  /** @format int32 */
  siteKey?: number | null;
}

export interface SimpleShowResPagedData {
  list?: SimpleShowRes[];
  pagination?: Pagination;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  amazonAffiliateLink?: string | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SimplecastShowMetaRes {
  podcastId?: string | null;
}

export interface SiteShowSummaryRes {
  featuredShow?: SimpleShowRes;
  organization?: OrganizationResponse;
  /** @format int32 */
  showCount: number;
  site?: SimpleSiteResponseRecord;
}

export interface SiteShowSummaryResPagedData {
  list?: SiteShowSummaryRes[];
  pagination?: Pagination;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SitesV1EpisodesListParams {
  /** @format int32 */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  showKey: number;
  /** @format int32 */
  year?: number;
}

export interface SlimCoachResponse {
  fullName?: string;
  /** @format int64 */
  key: number;
}

export interface SlimOrganizationRes {
  fullName?: string;
  /** @format int32 */
  key: number;
}

export interface SlimVisitResponse {
  /** @format int64 */
  dateOccurred: number;
  official: boolean;
  /** @format int32 */
  organizationKey: number;
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export interface SocialPostSummaryResponse {
  /** @format int64 */
  followers: number;
  socialType: SocialType;
  type?: string;
}

export interface SocialPostSummaryResponsePagedData {
  list?: SocialPostSummaryResponse[];
  pagination?: Pagination;
}

export enum SocialType {
  Unknown = 'Unknown',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export enum SortByCoachColumn {
  Position = 'Position',
  School = 'School',
  Salary = 'Salary',
  Agent = 'Agent',
  Agency = 'Agency',
  SecondaryAgent = 'SecondaryAgent',
  SecondaryAgency = 'SecondaryAgency',
}

export enum SortByIndustry {
  On3Consensus = 'On3Consensus',
  On3 = 'On3',
  TwentyFourSevenSports = 'TwentyFourSevenSports',
  Espn = 'Espn',
  Rivals = 'Rivals',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortMeasurementType {
  Latest = 'Latest',
  Lowest = 'Lowest',
  Highest = 'Highest',
}

export interface SportBase {
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SportFilter {
  abbreviation: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SpotifyShowMetaRes {
  url?: string | null;
}

export interface StateResponse {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface Status {
  classRank?: string | null;
  committedAsset?: KeyUrlBase;
  committedAssetRes?: AssetResponse;
  committedOrganization?: OrganizationBase;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  /** @format int64 */
  recruitmentKey: number;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  transferredAsset?: KeyUrlBase;
  transferredAssetRes?: AssetResponse;
  type?: string;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface StringPersonContactInfoMetaResponse {
  value?: string | null;
  verification?: VerificationResponse;
  visibility: Visibility;
}

export interface Team {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: KeyUrlBase;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface TeamConferenceFilter {
  conferenceAbbr?: string | null;
  /** @format int32 */
  conferenceKey?: number | null;
  teams?: TeamFilter[];
}

export interface TeamFilter {
  mascot?: string | null;
  /** @format int32 */
  orgKey?: number | null;
  orgName?: string | null;
  /** @format int32 */
  siteKey?: number | null;
  siteSlug?: string | null;
}

export enum TeamRankingSort {
  Score = 'Score',
  AverageRating = 'AverageRating',
  TotalCommits = 'TotalCommits',
  FiveStar = 'FiveStar',
  FourStar = 'FourStar',
  ThreeStar = 'ThreeStar',
}

export interface TeamTargetResponse {
  /** @format int32 */
  classRank?: number | null;
  coaches?: Coach[];
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  /** @format int32 */
  draftPositionCount: number;
  /** @format int32 */
  draftTotal: number;
  /** @format int32 */
  interest: number;
  latestVisit?: SlimVisitResponse;
  /** @format int32 */
  officialVisitCount: number;
  positionAbbreviation?: string | null;
  /** @format int32 */
  positionKey: number;
  /** @format double */
  prediction?: number | null;
  sport?: SportBase;
  status?: string | null;
  team?: Team;
  /** @format int32 */
  unOfficialVisitCount: number;
  /** @format int32 */
  year: number;
}

export interface TeamTargetResponsePagedData {
  list?: TeamTargetResponse[];
  pagination?: Pagination;
}

export interface TrackAndFieldMeasurementResponseRecord {
  /** @format date-time */
  endDate?: string | null;
  eventDescription?: string | null;
  /** @format int64 */
  eventKey?: number | null;
  eventName?: string | null;
  isVerified: boolean;
  /** @format int64 */
  measurementKey: number;
  measurementType?: MeasurementTypeResponse;
  /** @format int64 */
  measurementTypeKey: number;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  record: MeasurementRecord;
  /** @format date-time */
  startDate?: string | null;
  /** @format double */
  value?: number | null;
}

export interface TrackAndFieldMeasurementResponseRecordPagedData {
  list?: TrackAndFieldMeasurementResponseRecord[];
  pagination?: Pagination;
}

export enum TransferEligibility {
  Immediate = 'Immediate',
  Tbd = 'Tbd',
  PendingAppeal = 'PendingAppeal',
  SittingOne = 'SittingOne',
}

export interface TrendingListItemResponse {
  /** @format int32 */
  displayOrder: number;
  headline: string;
  /** @format int64 */
  key: number;
  subject: string;
  /** @format int64 */
  trendingListKey: number;
  type?: TrendingListItemType;
  url: string;
}

export interface TrendingListItemResponseTrendingListResponseRelatedPagedData {
  list?: TrendingListItemResponse[];
  pagination?: Pagination;
  relatedModel?: TrendingListResponse;
}

export enum TrendingListItemType {
  Unknown = 'Unknown',
  Breaking = 'Breaking',
  Hot = 'Hot',
  Live = 'Live',
  New = 'New',
  Trending = 'Trending',
}

export interface TrendingListResponse {
  /** @format int64 */
  key: number;
  name: string;
  sites?: number[];
}

/**
 * Response representing a user and the JWT
 * associated
 */
export interface UserJwtResponse {
  /** The display name of the user */
  displayName?: string | null;
  email: string | null;
  /** Does the user have at least one active subscription */
  hasActiveSubscription: boolean;
  latestTransactionId?: string;
  /** @format int64 */
  personKey?: number | null;
  personKeys?: number[];
  /** The pianoId of the user */
  pianoId?: string | null;
  promotionEligible: boolean;
  /**
   * The time when the Token will be expired
   * @format date-time
   */
  refreshBefore: string;
  /** The refresh token used to get a new Token */
  refreshToken?: string;
  /**
   * The date/time the refresh token expires
   * @format date-time
   */
  refreshTokenExpiry: string;
  /** The list of string roles */
  roles?: string[];
  sites?: number[];
  /** What is the max type of subscription a user has */
  subscriptionMaxType?: string;
  /** List of active subscription keys */
  subscriptions?: number[];
  /** The JWT to use */
  token?: string;
  /**
   * The id of the user
   * @format int64
   */
  userId: number;
  /** The username of the user */
  username?: string;
}

export interface UserProfileBase {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string;
  profilePicture?: KeyUrlBase;
  profilePictureResponse?: ImageResponse;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface UserProfileResponseRecord {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string | null;
  profileAsset?: AssetResponse;
  profilePicture?: SimpleImageResponseRecord;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface V1DealsListParams {
  gender?: Gender;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
}

export interface V1OrganizationsDealsDetailParams {
  gender?: Gender;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /** @format int32 */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
}

export interface V1PeopleDealsDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1PlayersDealsDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface ValuationGrowthResponse {
  /** @format int32 */
  intervalAmount: number;
  intervalType?: string;
  list?: ValuationTimelineResponse[];
  pagedList?: ValuationTimelineResponsePagedData;
  /** @format int64 */
  valuationChange: number;
  /** @format int64 */
  valuationStart: number;
}

export interface ValuationResponse {
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  totalValue?: number | null;
}

export interface ValuationTimelineResponse {
  date?: string;
  /** @format int64 */
  dateUnix: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  valuation?: number | null;
  /** @format int64 */
  valuationChange?: number | null;
}

export interface ValuationTimelineResponsePagedData {
  list?: ValuationTimelineResponse[];
  pagination?: Pagination;
}

export interface VerificationResponse {
  /** @format int64 */
  userKey: number;
  verificationSource: VerificationSource;
  /** @format date-time */
  verifiedOn: string;
}

export enum VerificationSource {
  Athlete = 'Athlete',
  DBAdmin = 'DBAdmin',
  Unknown = 'Unknown',
}

export interface VideoResponse {
  category?: Int32StringKeyValuePair;
  /** @format int32 */
  date: number;
  description?: string | null;
  /** @format int32 */
  featuredOrder?: number | null;
  isFeatured: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey?: number | null;
  personSport?: PersonSportBase;
  sourceUrl?: string;
  thumbnail?: string | null;
  title?: string | null;
}

export interface VideoResponsePagedData {
  list?: VideoResponse[];
  pagination?: Pagination;
}

export enum Visibility {
  Private = 'Private',
  Friends = 'Friends',
  Athletes = 'Athletes',
  Public = 'Public',
  Unknown = 'Unknown',
}

export interface Visit {
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  key: number;
  upcoming: boolean;
}

export interface VisitCenterCard {
  coachingVisits?: CoachingVisitResponse[];
  /** @format int32 */
  count?: number | null;
  /** @format int64 */
  key: number;
  officialVisits?: Visit[];
  /** @format int32 */
  orgInterest: number;
  organization: VisitCenterOrg;
  percent: string;
  /** @format double */
  prediction?: number | null;
  unofficialVisits?: Visit[];
}

export interface VisitCenterOrg {
  abbreviation?: string | null;
  fullName: string;
  /** @format int64 */
  key: number;
  name: string;
  slug?: string | null;
  url?: string | null;
}

export interface VisitCenterResponse {
  sport?: SportBase;
  /** @format int32 */
  totalVisits: number;
  visits?: VisitCenterCard[];
  /** @format int32 */
  year: number;
}

export interface YouTubeShowMetaRes {
  url?: string | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Coach
 * @name RdbV1CoachesAgentsDetail
 * @summary Gets the coaches by agent
 * @request GET:/public/rdb/v1/coaches/{coachKey}/agents
 */
export const rdbV1CoachesAgentsDetail = (
  client: AxiosInstance,
  { coachKey, ...query }: RdbV1CoachesAgentsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<AgentCoachResponse>({
      url: `/public/rdb/v1/coaches/${coachKey}/agents`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Coach
 * @name RdbV1CoachesHistoryDetail
 * @summary Gets the history for the given coach key
 * @request GET:/public/rdb/v1/coaches/{personKey}/history
 */
export const rdbV1CoachesHistoryDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1CoachesHistoryDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CoachingHistoryResponsePagedData>({
      url: `/public/rdb/v1/coaches/${personKey}/history`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Coach
 * @name RdbV1CoachesProfileDetail
 * @summary Gets the profile for the given coach key
 * @request GET:/public/rdb/v1/coaches/{personKey}/profile
 */
export const rdbV1CoachesProfileDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1CoachesProfileDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CoachResponse>({
      url: `/public/rdb/v1/coaches/${personKey}/profile`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Coach
 * @name RdbV1CoachesSalariesList
 * @summary Gets the coaches by salary
 * @request GET:/public/rdb/v1/coaches/salaries
 */
export const rdbV1CoachesSalariesList = (
  client: AxiosInstance,
  query: RdbV1CoachesSalariesListParams,
  params: RequestParams = {},
) =>
  client
    .request<CoachResponsePagedData>({
      url: `/public/rdb/v1/coaches/salaries`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Coach
 * @name RdbV1CoachesSlimProfileDetail
 * @summary Gets the profile for the given coach key
 * @request GET:/public/rdb/v1/coaches/{personKey}/slim-profile
 */
export const rdbV1CoachesSlimProfileDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1CoachesSlimProfileDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SlimCoachResponse>({
      url: `/public/rdb/v1/coaches/${personKey}/slim-profile`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name RdbV1CollectiveGroupsDealsDetail
 * @summary Gets all NIL Deals for a collective
 * @request GET:/public/rdb/v1/collective-groups/{key}/deals
 */
export const rdbV1CollectiveGroupsDealsDetail = (
  client: AxiosInstance,
  { key, ...query }: RdbV1CollectiveGroupsDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicPlayerDealResponseCollectiveGroupResponseRelatedPagedData>({
      url: `/public/rdb/v1/collective-groups/${key}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name RdbV1CollectiveGroupsDetail
 * @request GET:/public/rdb/v1/collective-groups/{key}
 */
export const rdbV1CollectiveGroupsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveGroupResponse>({
      url: `/public/rdb/v1/collective-groups/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name RdbV1CollectiveGroupsList
 * @request GET:/public/rdb/v1/collective-groups
 */
export const rdbV1CollectiveGroupsList = (
  client: AxiosInstance,
  query: RdbV1CollectiveGroupsListParams,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveGroupResponsePagedData>({
      url: `/public/rdb/v1/collective-groups`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Commit
 * @name RdbV1CommitsLatestList
 * @summary Gets Latest Commits
 * @request GET:/public/rdb/v1/commits/latest
 */
export const rdbV1CommitsLatestList = (
  client: AxiosInstance,
  query: RdbV1CommitsLatestListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerListBasePagedData>({
      url: `/public/rdb/v1/commits/latest`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Commit
 * @name RdbV1CommitsOrganizationsDetail
 * @summary Gets the commits, offers or decommits based on the team, status, year, sport
 * @request GET:/public/rdb/v1/commits/organizations/{orgKey}
 * @deprecated
 */
export const rdbV1CommitsOrganizationsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV1CommitsOrganizationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CommitResponse>({
      url: `/public/rdb/v1/commits/organizations/${orgKey}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Commit
 * @name RdbV1CommitsOrganizationsLatestCommitsDetail
 * @request GET:/public/rdb/v1/commits/organizations/{orgKey}/latest-commits
 * @deprecated
 */
export const rdbV1CommitsOrganizationsLatestCommitsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV1CommitsOrganizationsLatestCommitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CommitResponsePaged>({
      url: `/public/rdb/v1/commits/organizations/${orgKey}/latest-commits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Year
 * @name RdbV1DefaultYearList
 * @request GET:/public/rdb/v1/default-year
 */
export const rdbV1DefaultYearList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<CurrentSportYearRecord>({
      url: `/public/rdb/v1/default-year`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Year
 * @name RdbV1DefaultYearUpdate
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request PUT:/public/rdb/v1/default-year
 * @secure
 */
export const rdbV1DefaultYearUpdate = (
  client: AxiosInstance,
  query: RdbV1DefaultYearUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/public/rdb/v1/default-year`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftOrganizationRankList
 * @request GET:/public/rdb/v1/draft-organization-rank
 */
export const rdbV1DraftOrganizationRankList = (
  client: AxiosInstance,
  query: RdbV1DraftOrganizationRankListParams,
  params: RequestParams = {},
) =>
  client
    .request<RankedOrganizationsDraftResponsePagedData>({
      url: `/public/rdb/v1/draft-organization-rank`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftPickOrganizationRankList
 * @request GET:/public/rdb/v1/draft-pick-organization-rank
 */
export const rdbV1DraftPickOrganizationRankList = (
  client: AxiosInstance,
  query: RdbV1DraftPickOrganizationRankListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationDraftPickRankedResponsePagedData>({
      url: `/public/rdb/v1/draft-pick-organization-rank`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftsByStarsList
 * @request GET:/public/rdb/v1/drafts-by-stars
 */
export const rdbV1DraftsByStarsList = (
  client: AxiosInstance,
  query: RdbV1DraftsByStarsListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftByStarsResponsePagedData>({
      url: `/public/rdb/v1/drafts-by-stars`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftsByStarsSummaryList
 * @request GET:/public/rdb/v1/drafts-by-stars-summary
 */
export const rdbV1DraftsByStarsSummaryList = (
  client: AxiosInstance,
  query: RdbV1DraftsByStarsSummaryListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftByStarsSummaryResponse>({
      url: `/public/rdb/v1/drafts-by-stars-summary`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftsList
 * @summary Gets the draft off a round, sport, year
 * @request GET:/public/rdb/v1/drafts
 */
export const rdbV1DraftsList = (
  client: AxiosInstance,
  query: RdbV1DraftsListParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPlayerResponse[]>({
      url: `/public/rdb/v1/drafts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1DraftsPlayersDetail
 * @request GET:/public/rdb/v1/drafts/{orgKey}/players
 */
export const rdbV1DraftsPlayersDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV1DraftsPlayersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftByOrgResponse[]>({
      url: `/public/rdb/v1/drafts/${orgKey}/players`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersConferencesList
 * @summary Gets the conferences
 * @request GET:/public/rdb/v1/filters/conferences
 */
export const rdbV1FiltersConferencesList = (
  client: AxiosInstance,
  query: RdbV1FiltersConferencesListParams,
  params: RequestParams = {},
) =>
  client
    .request<ConferenceFilter[]>({
      url: `/public/rdb/v1/filters/conferences`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersDraftRoundsList
 * @summary Gets the draft rounds for a year and sport
 * @request GET:/public/rdb/v1/filters/draft-rounds
 */
export const rdbV1FiltersDraftRoundsList = (
  client: AxiosInstance,
  query: RdbV1FiltersDraftRoundsListParams,
  params: RequestParams = {},
) =>
  client
    .request<string[]>({
      url: `/public/rdb/v1/filters/draft-rounds`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersPositionsList
 * @summary Gets the positions
 * @request GET:/public/rdb/v1/filters/positions
 */
export const rdbV1FiltersPositionsList = (
  client: AxiosInstance,
  query: RdbV1FiltersPositionsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PositionFilter[]>({
      url: `/public/rdb/v1/filters/positions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersSportsList
 * @summary Gets the sport types
 * @request GET:/public/rdb/v1/filters/sports
 */
export const rdbV1FiltersSportsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SportFilter[]>({
      url: `/public/rdb/v1/filters/sports`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersStatusList
 * @summary Gets the player commit statuses
 * @request GET:/public/rdb/v1/filters/status
 */
export const rdbV1FiltersStatusList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<string[]>({
      url: `/public/rdb/v1/filters/status`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersTeamsList
 * @summary Gets a list of teams and optional group by conference
 * @request GET:/public/rdb/v1/filters/teams
 */
export const rdbV1FiltersTeamsList = (
  client: AxiosInstance,
  query: RdbV1FiltersTeamsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamConferenceFilter[]>({
      url: `/public/rdb/v1/filters/teams`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Filter
 * @name RdbV1FiltersYearsList
 * @summary Gets the the years from 1999-(Current + 4)
 * @request GET:/public/rdb/v1/filters/years
 */
export const rdbV1FiltersYearsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/public/rdb/v1/filters/years`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1Nil100List
 * @request GET:/public/rdb/v1/nil-100
 */
export const rdbV1Nil100List = (
  client: AxiosInstance,
  query: RdbV1Nil100ListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilHundredResponse[]>({
      url: `/public/rdb/v1/nil-100`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilCompliance
 * @name RdbV1NilCompliancesList
 * @request GET:/public/rdb/v1/nil-compliances
 */
export const rdbV1NilCompliancesList = (
  client: AxiosInstance,
  query: RdbV1NilCompliancesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilComplianceResponsePagedData>({
      url: `/public/rdb/v1/nil-compliances`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilCompliance
 * @name RdbV1NilCompliancesStateList
 * @request GET:/public/rdb/v1/nil-compliances/state
 */
export const rdbV1NilCompliancesStateList = (
  client: AxiosInstance,
  query: RdbV1NilCompliancesStateListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilComplianceResponse>({
      url: `/public/rdb/v1/nil-compliances/state`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1NilRankingsList
 * @request GET:/public/rdb/v1/nil-rankings
 */
export const rdbV1NilRankingsList = (
  client: AxiosInstance,
  query: RdbV1NilRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilRankingResponseRelatedNilRankingResponseRelatedPagedData>({
      url: `/public/rdb/v1/nil-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsAdvancedSearchList
 * @request GET:/public/rdb/v1/organizations/advanced-search
 */
export const rdbV1OrganizationsAdvancedSearchList = (
  client: AxiosInstance,
  query: RdbV1OrganizationsAdvancedSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicOrganizationSearchResponsePagedData>({
      url: `/public/rdb/v1/organizations/advanced-search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsAutocompletePaginatedList
 * @request GET:/public/rdb/v1/organizations/autocomplete-paginated
 */
export const rdbV1OrganizationsAutocompletePaginatedList = (
  client: AxiosInstance,
  query: RdbV1OrganizationsAutocompletePaginatedListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgAutocompleteResponsePagedData>({
      url: `/public/rdb/v1/organizations/autocomplete-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Roster
 * @name RdbV1OrganizationsCoachRosterDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/coach-roster
 */
export const rdbV1OrganizationsCoachRosterDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsCoachRosterDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CoachRosterItemResponseOrganizationRosterResponseRelatedPagedData>(
      {
        url: `/public/rdb/v1/organizations/${organizationKey}/coach-roster`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsDetail
 * @request GET:/public/rdb/v1/organizations/{key}
 */
export const rdbV1OrganizationsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DetailedOrganizationRes>({
      url: `/public/rdb/v1/organizations/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftClassByStateDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/draft-class-by-state
 */
export const rdbV1OrganizationsDraftClassByStateDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: RdbV1OrganizationsDraftClassByStateDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickStateResponsePagedData>({
      url: `/public/rdb/v1/organizations/${organizationKey}/draft-class-by-state`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftClassByYearDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/draft-class-by-year
 */
export const rdbV1OrganizationsDraftClassByYearDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsDraftClassByYearDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationDraftYearsResponsePagedData>({
      url: `/public/rdb/v1/organizations/${organizationKey}/draft-class-by-year`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftCountByStarsDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/draft-count-by-stars
 */
export const rdbV1OrganizationsDraftCountByStarsDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: RdbV1OrganizationsDraftCountByStarsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickStarsResponse>({
      url: `/public/rdb/v1/organizations/${organizationKey}/draft-count-by-stars`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftCountByYearDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/draft-count-by-year
 */
export const rdbV1OrganizationsDraftCountByYearDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsDraftCountByYearDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftPickYearResponsePagedData>({
      url: `/public/rdb/v1/organizations/${organizationKey}/draft-count-by-year`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftedPlayersDetail
 * @request GET:/public/rdb/v1/organizations/{orgKey}/drafted-players
 */
export const rdbV1OrganizationsDraftedPlayersDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV1OrganizationsDraftedPlayersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DraftByOrgResponse[]>({
      url: `/public/rdb/v1/organizations/${orgKey}/drafted-players`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftRankingSummaryDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/draft-ranking-summary
 */
export const rdbV1OrganizationsDraftRankingSummaryDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: RdbV1OrganizationsDraftRankingSummaryDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationDraftRankingSummaryResponse>({
      url: `/public/rdb/v1/organizations/${organizationKey}/draft-ranking-summary`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Draft
 * @name RdbV1OrganizationsDraftsByStarsSummaryDetail
 * @request GET:/public/rdb/v1/organizations/{orgKey}/drafts-by-stars-summary
 */
export const rdbV1OrganizationsDraftsByStarsSummaryDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV1OrganizationsDraftsByStarsSummaryDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgDraftByStarsSummaryResponse>({
      url: `/public/rdb/v1/organizations/${orgKey}/drafts-by-stars-summary`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsOrgTypeClassHierarchiesDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/org-type-class-hierarchies
 */
export const rdbV1OrganizationsOrgTypeClassHierarchiesDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: RdbV1OrganizationsOrgTypeClassHierarchiesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgTypeClassHierarchyResPagedData>({
      url: `/public/rdb/v1/organizations/${organizationKey}/org-type-class-hierarchies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Roster
 * @name RdbV1OrganizationsRosterDetail
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/roster
 */
export const rdbV1OrganizationsRosterDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsRosterDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RosterItemResponseOrganizationRosterResponseRelatedPagedData>({
      url: `/public/rdb/v1/organizations/${organizationKey}/roster`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Roster
 * @name RdbV1OrganizationsRosterHeaderDetail
 * @summary Gets the roster header for the organization
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/roster-header
 */
export const rdbV1OrganizationsRosterHeaderDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsRosterHeaderDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RosterHeaderResponse>({
      url: `/public/rdb/v1/organizations/${organizationKey}/roster-header`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsSearchList
 * @request GET:/public/rdb/v1/organizations/search
 */
export const rdbV1OrganizationsSearchList = (
  client: AxiosInstance,
  query: RdbV1OrganizationsSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdvancedOrgSearchResponsePagedData>({
      url: `/public/rdb/v1/organizations/search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsSlimProfileDetail
 * @request GET:/public/rdb/v1/organizations/{key}/slim-profile
 */
export const rdbV1OrganizationsSlimProfileDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SlimOrganizationRes>({
      url: `/public/rdb/v1/organizations/${key}/slim-profile`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Organization
 * @name RdbV1OrganizationsTypesList
 * @request GET:/public/rdb/v1/organizations/types
 */
export const rdbV1OrganizationsTypesList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<Int32StringKeyValuePair[]>({
      url: `/public/rdb/v1/organizations/types`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name RdbV1PeopleCombineMeasurementsDetail
 * @request GET:/public/rdb/v1/people/{personKey}/combine-measurements
 */
export const rdbV1PeopleCombineMeasurementsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PublicCombineMeasurementResponseRecord>({
      url: `/public/rdb/v1/people/${personKey}/combine-measurements`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name RdbV1PeopleDatabaseUpdatesDetail
 * @request GET:/public/rdb/v1/people/{personKey}/database-updates
 */
export const rdbV1PeopleDatabaseUpdatesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleDatabaseUpdatesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DatabaseUpdateResponsePagedData>({
      url: `/public/rdb/v1/people/${personKey}/database-updates`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name RdbV1PeopleImagesDetail
 * @summary Gets the images for the given player key
 * @request GET:/public/rdb/v1/people/{personKey}/images
 */
export const rdbV1PeopleImagesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleImagesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ImageResponsePagedData>({
      url: `/public/rdb/v1/people/${personKey}/images`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1PeopleLatestValuationDetail
 * @summary Gets the latest valuation for person key
 * @request GET:/public/rdb/v1/people/{personKey}/latest-valuation
 */
export const rdbV1PeopleLatestValuationDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonValuationResponse>({
      url: `/public/rdb/v1/people/${personKey}/latest-valuation`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name RdbV1PeopleMeasurementsAveragesDetail
 * @request GET:/public/rdb/v1/people/{personKey}/measurements/averages
 */
export const rdbV1PeopleMeasurementsAveragesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleMeasurementsAveragesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicMeasurementAveragesResponseRecordMeasurementComparisonRelatedResponseRelatedPagedData>(
      {
        url: `/public/rdb/v1/people/${personKey}/measurements/averages`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name RdbV1PeopleMeasurementsDetail
 * @request GET:/public/rdb/v1/people/{personKey}/measurements
 */
export const rdbV1PeopleMeasurementsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleMeasurementsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicMeasurementPersonResponseRecord>({
      url: `/public/rdb/v1/people/${personKey}/measurements`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name RdbV1PeoplePersonConnectionsDetail
 * @summary Gets Person Connections by Person Key
 * @request GET:/public/rdb/v1/people/{personKey}/person-connections
 * @deprecated
 */
export const rdbV1PeoplePersonConnectionsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeoplePersonConnectionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponsePagedData>({
      url: `/public/rdb/v1/people/${personKey}/person-connections`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1PeopleSocialDetail
 * @summary Gets the social media handle for person key
 * @request GET:/public/rdb/v1/people/{personKey}/social
 */
export const rdbV1PeopleSocialDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonInfluencerResponse[]>({
      url: `/public/rdb/v1/people/${personKey}/social`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1PeopleSocialPostSummaryDetail
 * @summary Gets the social media post count summary for person key
 * @request GET:/public/rdb/v1/people/{personKey}/social-post-summary
 */
export const rdbV1PeopleSocialPostSummaryDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SocialPostSummaryResponsePagedData>({
      url: `/public/rdb/v1/people/${personKey}/social-post-summary`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Measurement
 * @name RdbV1PeopleTrackAndFieldMeasurementsDetail
 * @request GET:/public/rdb/v1/people/{personKey}/track-and-field-measurements
 */
export const rdbV1PeopleTrackAndFieldMeasurementsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleTrackAndFieldMeasurementsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<TrackAndFieldMeasurementResponseRecordPagedData>({
      url: `/public/rdb/v1/people/${personKey}/track-and-field-measurements`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV1PeopleValuationGrowthDetail
 * @summary Gets the valuation growth for person key
 * @request GET:/public/rdb/v1/people/{personKey}/valuation-growth
 */
export const rdbV1PeopleValuationGrowthDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleValuationGrowthDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ValuationGrowthResponse>({
      url: `/public/rdb/v1/people/${personKey}/valuation-growth`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name RdbV1PersonConnectionsDetail
 * @summary Gets Person Connection by Connection Key
 * @request GET:/public/rdb/v1/person-connections/{connectionKey}
 */
export const rdbV1PersonConnectionsDetail = (
  client: AxiosInstance,
  connectionKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/public/rdb/v1/person-connections/${connectionKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name RdbV1PersonPrimaryRecruitmentEvaluationDetail
 * @summary Gets the recruitment evaluations for the given player key
 * @request GET:/public/rdb/v1/person/{personKey}/primary-recruitment-evaluation
 */
export const rdbV1PersonPrimaryRecruitmentEvaluationDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse>({
      url: `/public/rdb/v1/person/${personKey}/primary-recruitment-evaluation`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name RdbV1PersonRecruitmentEvaluationsDetail
 * @summary Gets the profile for the given player key
 * @request GET:/public/rdb/v1/person/{personKey}/recruitment-evaluations
 */
export const rdbV1PersonRecruitmentEvaluationsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PersonRecruitmentEvaluationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponsePagedData>({
      url: `/public/rdb/v1/person/${personKey}/recruitment-evaluations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name RdbV1PersonVideosDetail
 * @summary Gets the videos for the given player key
 * @request GET:/public/rdb/v1/person/{personKey}/videos
 */
export const rdbV1PersonVideosDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PersonVideosDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponsePagedData>({
      url: `/public/rdb/v1/person/${personKey}/videos`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerAgentsDetail
 * @request GET:/public/rdb/v1/player/{personKey}/agents
 */
export const rdbV1PlayerAgentsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AgentPlayersResponse>({
      url: `/public/rdb/v1/player/${personKey}/agents`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name RdbV1PlayerAllRankingsDetail
 * @summary Gets all types of rating and ranking for the given sport key and year
 * @request GET:/public/rdb/v1/player/{personKey}/all-rankings
 */
export const rdbV1PlayerAllRankingsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerAllRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerRatingAllResponse[]>({
      url: `/public/rdb/v1/player/${personKey}/all-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerAnnouncementImagesDetail
 * @request GET:/public/rdb/v1/player/{personKey}/announcement-images
 */
export const rdbV1PlayerAnnouncementImagesDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<CompletedAnnouncementImage[]>({
      url: `/public/rdb/v1/player/${personKey}/announcement-images`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerContactInfoDetail
 * @request GET:/public/rdb/v1/player/{personKey}/contact-info
 */
export const rdbV1PlayerContactInfoDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PublicPersonContactInfoResponse>({
      url: `/public/rdb/v1/player/${personKey}/contact-info`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DatabaseUpdate
 * @name RdbV1PlayerDatabaseUpdatesDetail
 * @request GET:/public/rdb/v1/player/{personKey}/database-updates
 */
export const rdbV1PlayerDatabaseUpdatesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerDatabaseUpdatesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DatabaseUpdateResponsePagedData>({
      url: `/public/rdb/v1/player/${personKey}/database-updates`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerEducationDetail
 * @request GET:/public/rdb/v1/player/{personKey}/education
 */
export const rdbV1PlayerEducationDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonEducationResponse>({
      url: `/public/rdb/v1/player/${personKey}/education`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name RdbV1PlayerImagesDetail
 * @summary Gets the images for the given player key
 * @request GET:/public/rdb/v1/player/{personKey}/images
 */
export const rdbV1PlayerImagesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerImagesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ImageResponsePagedData>({
      url: `/public/rdb/v1/player/${personKey}/images`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerLookUpMappingList
 * @request GET:/public/rdb/v1/player/look-up-mapping
 */
export const rdbV1PlayerLookUpMappingList = (
  client: AxiosInstance,
  query: RdbV1PlayerLookUpMappingListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerProfileResponse>({
      url: `/public/rdb/v1/player/look-up-mapping`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerOrganizationsDetail
 * @summary Gets the org information for the given player, org, and sport key
 * @request GET:/public/rdb/v1/player/{playerKey}/organizations/{orgKey}
 */
export const rdbV1PlayerOrganizationsDetail = (
  client: AxiosInstance,
  { playerKey, orgKey, ...query }: RdbV1PlayerOrganizationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ProfileOrgResponse>({
      url: `/public/rdb/v1/player/${playerKey}/organizations/${orgKey}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerOrganizationsDetail2
 * @summary Gets the organizations for the given person sport
 * @request GET:/public/rdb/v1/player/{personSportKey}/organizations
 * @originalName rdbV1PlayerOrganizationsDetail
 * @duplicate
 */
export const rdbV1PlayerOrganizationsDetail2 = (
  client: AxiosInstance,
  personSportKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PlayerOrgResponse>({
      url: `/public/rdb/v1/player/${personSportKey}/organizations`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerProfileApplyCreate
 * @summary  (Roles: registered user)
 * @request POST:/public/rdb/v1/player/profile/apply
 * @deprecated
 * @secure
 */
export const rdbV1PlayerProfileApplyCreate = (
  client: AxiosInstance,
  data: PlayerProfileRequest,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/public/rdb/v1/player/profile/apply`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerProfileDetail
 * @summary Gets the profile for the given player key
 * @request GET:/public/rdb/v1/player/{personKey}/profile
 */
export const rdbV1PlayerProfileDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerProfileDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerProfileResponse>({
      url: `/public/rdb/v1/player/${personKey}/profile`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name RdbV1PlayerRankingsDetail
 * @summary Gets the rating and ranking for the given player key
 * @request GET:/public/rdb/v1/player/{personKey}/rankings
 */
export const rdbV1PlayerRankingsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerRatingResponse>({
      url: `/public/rdb/v1/player/${personKey}/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerSecondarySportsDetail
 * @request GET:/public/rdb/v1/player/{personKey}/secondary-sports
 */
export const rdbV1PlayerSecondarySportsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SecondaryPersonSportResponsePagedData>({
      url: `/public/rdb/v1/player/${personKey}/secondary-sports`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name RdbV1PlayersIndustryComparisionList
 * @summary Gets all types of rating and ranking for players
 * @request GET:/public/rdb/v1/players/industry-comparision
 */
export const rdbV1PlayersIndustryComparisionList = (
  client: AxiosInstance,
  query: RdbV1PlayersIndustryComparisionListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerRatingAllAndPersonResponsePagedData>({
      url: `/public/rdb/v1/players/industry-comparision`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name RdbV1PlayersIndustryComparisionListList
 * @summary Gets all types of rating and ranking for players
 * @request GET:/public/rdb/v1/players/industry-comparision-list
 */
export const rdbV1PlayersIndustryComparisionListList = (
  client: AxiosInstance,
  query: RdbV1PlayersIndustryComparisionListListParams,
  params: RequestParams = {},
) =>
  client
    .request<IndustryComparisonResponsePagedData>({
      url: `/public/rdb/v1/players/industry-comparision-list`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerTeamTargetsDetail
 * @summary Gets the team targets for the player profile recruiting section
 * @request GET:/public/rdb/v1/player/{playerKey}/team-targets
 */
export const rdbV1PlayerTeamTargetsDetail = (
  client: AxiosInstance,
  { playerKey, ...query }: RdbV1PlayerTeamTargetsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamTargetResponsePagedData>({
      url: `/public/rdb/v1/player/${playerKey}/team-targets`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerUnclaimProfileCreate
 * @summary  (Roles: registered user)
 * @request POST:/public/rdb/v1/player/{personKey}/unclaim-profile
 * @secure
 */
export const rdbV1PlayerUnclaimProfileCreate = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/public/rdb/v1/player/${personKey}/unclaim-profile`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerVerifiedList
 * @summary Gets the validated players
 * @request GET:/public/rdb/v1/player/verified
 */
export const rdbV1PlayerVerifiedList = (
  client: AxiosInstance,
  query: RdbV1PlayerVerifiedListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerProfileResponsePagedData>({
      url: `/public/rdb/v1/player/verified`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerVerifyCreate
 * @summary  (Roles: registered user)
 * @request POST:/public/rdb/v1/player/{personKey}/verify
 * @secure
 */
export const rdbV1PlayerVerifyCreate = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerVerifyCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/public/rdb/v1/player/${personKey}/verify`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name RdbV1PlayerVideosDetail
 * @summary Gets the videos for the given player key
 * @request GET:/public/rdb/v1/player/{personKey}/videos
 */
export const rdbV1PlayerVideosDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerVideosDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponsePagedData>({
      url: `/public/rdb/v1/player/${personKey}/videos`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name RdbV1PlayerVideosHighlightsDetail
 * @summary Gets the video highlights for the given player key
 * @request GET:/public/rdb/v1/player/{personKey}/videos/highlights
 */
export const rdbV1PlayerVideosHighlightsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PlayerVideosHighlightsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponsePagedData>({
      url: `/public/rdb/v1/player/${personKey}/videos/highlights`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerVisitCenterDetail
 * @summary Gets the visit center for the player profile recruiting section
 * @request GET:/public/rdb/v1/player/{playerKey}/visit-center
 */
export const rdbV1PlayerVisitCenterDetail = (
  client: AxiosInstance,
  { playerKey, ...query }: RdbV1PlayerVisitCenterDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VisitCenterResponse>({
      url: `/public/rdb/v1/player/${playerKey}/visit-center`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Player
 * @name RdbV1PlayerVisitsDetail
 * @summary Gets all the visits for a player
 * @request GET:/public/rdb/v1/player/{playerKey}/visits
 */
export const rdbV1PlayerVisitsDetail = (
  client: AxiosInstance,
  { playerKey, ...query }: RdbV1PlayerVisitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerVisitsRes>({
      url: `/public/rdb/v1/player/${playerKey}/visits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name RdbV1PredictionsDetail
 * @summary Gets the predictions for the orgs for a user key
 * @request GET:/public/rdb/v1/predictions/{userKey}
 */
export const rdbV1PredictionsDetail = (
  client: AxiosInstance,
  { userKey, ...query }: RdbV1PredictionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RpmPickListUserProfileResponseRecordRelatedPagedData>({
      url: `/public/rdb/v1/predictions/${userKey}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Quotes
 * @name RdbV1QuotesDetail
 * @summary Gets Quote by Key
 * @request GET:/public/rdb/v1/quotes/{key}
 */
export const rdbV1QuotesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<QuoteResponse>({
      url: `/public/rdb/v1/quotes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Quotes
 * @name RdbV1QuotesList
 * @summary Gets Quotes
 * @request GET:/public/rdb/v1/quotes
 */
export const rdbV1QuotesList = (
  client: AxiosInstance,
  query: RdbV1QuotesListParams,
  params: RequestParams = {},
) =>
  client
    .request<QuoteResponsePagedData>({
      url: `/public/rdb/v1/quotes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name RdbV1RecruitmentPrimaryRecruitmentEvaluationDetail
 * @summary Gets the recruitment for the given recruitment key
 * @request GET:/public/rdb/v1/recruitment/{recruitmentKey}/primary-recruitment-evaluation
 */
export const rdbV1RecruitmentPrimaryRecruitmentEvaluationDetail = (
  client: AxiosInstance,
  recruitmentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponse>({
      url: `/public/rdb/v1/recruitment/${recruitmentKey}/primary-recruitment-evaluation`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RecruitmentEvaluation
 * @name RdbV1RecruitmentRecruitmentEvaluationsDetail
 * @summary Gets the recruitment evaluations for the given recruitment key
 * @request GET:/public/rdb/v1/recruitment/{recruitmentKey}/recruitment-evaluations
 */
export const rdbV1RecruitmentRecruitmentEvaluationsDetail = (
  client: AxiosInstance,
  {
    recruitmentKey,
    ...query
  }: RdbV1RecruitmentRecruitmentEvaluationsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RecruitmentEvaluationResponsePagedData>({
      url: `/public/rdb/v1/recruitment/${recruitmentKey}/recruitment-evaluations`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name RdbV1RecruitmentsLatestRpmPicksList
 * @summary Gets the RPM Picks
 * @request GET:/public/rdb/v1/recruitments/latest-rpm-picks
 */
export const rdbV1RecruitmentsLatestRpmPicksList = (
  client: AxiosInstance,
  query: RdbV1RecruitmentsLatestRpmPicksListParams,
  params: RequestParams = {},
) =>
  client
    .request<RpmPickListPredictionAccuraciesRelatedPagedData>({
      url: `/public/rdb/v1/recruitments/latest-rpm-picks`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name RdbV1RecruitmentsProfileDetail
 * @summary Gets the rating and ranking for the given player key
 * @request GET:/public/rdb/v1/recruitments/{recKey}/profile
 */
export const rdbV1RecruitmentsProfileDetail = (
  client: AxiosInstance,
  recKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ProfileRecruitResponse>({
      url: `/public/rdb/v1/recruitments/${recKey}/profile`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name RdbV1RecruitmentsRpmPicksDetail
 * @summary Gets the predictions for the orgs for a rec key
 * @request GET:/public/rdb/v1/recruitments/{recKey}/rpm-picks
 */
export const rdbV1RecruitmentsRpmPicksDetail = (
  client: AxiosInstance,
  recKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RpmPicks[]>({
      url: `/public/rdb/v1/recruitments/${recKey}/rpm-picks`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name RdbV1RecruitmentsRpmSummaryDetail
 * @summary Gets the predictions for the orgs for a rec key
 * @request GET:/public/rdb/v1/recruitments/{recKey}/rpm-summary
 */
export const rdbV1RecruitmentsRpmSummaryDetail = (
  client: AxiosInstance,
  recKey: number,
  params: RequestParams = {},
) =>
  client
    .request<RpmSummaryResponse>({
      url: `/public/rdb/v1/recruitments/${recKey}/rpm-summary`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name RdbV1TeamRankingBluechipsTeamRankingsDetail
 * @summary Gets the On3 blue chips rankings
 * @request GET:/public/rdb/v1/team-ranking/{sport}-{year}/bluechips-team-rankings
 */
export const rdbV1TeamRankingBluechipsTeamRankingsDetail = (
  client: AxiosInstance,
  { sport, year, ...query }: RdbV1TeamRankingBluechipsTeamRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationBlueChipsRankingResponseRankingResponseRelatedPagedData>(
      {
        url: `/public/rdb/v1/team-ranking/${sport}-${year}/bluechips-team-rankings`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name RdbV1TeamRankingConsensusTeamRankingsDetail
 * @summary Gets the industry team rankings
 * @request GET:/public/rdb/v1/team-ranking/{sport}-{year}/consensus-team-rankings
 */
export const rdbV1TeamRankingConsensusTeamRankingsDetail = (
  client: AxiosInstance,
  { sport, year, ...query }: RdbV1TeamRankingConsensusTeamRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingResponseRankingResponseRelatedPagedData>({
      url: `/public/rdb/v1/team-ranking/${sport}-${year}/consensus-team-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name RdbV1TeamRankingOrganizationsSummaryDetail
 * @summary Gets the organization ranking summary
 * @request GET:/public/rdb/v1/team-ranking/organizations/{organizationKey}/summary
 */
export const rdbV1TeamRankingOrganizationsSummaryDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: RdbV1TeamRankingOrganizationsSummaryDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingSummaryResponse>({
      url: `/public/rdb/v1/team-ranking/organizations/${organizationKey}/summary`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name RdbV1TeamRankingTeamRankingsDetail
 * @summary Gets the On3 team rankings
 * @request GET:/public/rdb/v1/team-ranking/{sport}-{year}/team-rankings
 */
export const rdbV1TeamRankingTeamRankingsDetail = (
  client: AxiosInstance,
  { sport, year, ...query }: RdbV1TeamRankingTeamRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingResponseRankingResponseRelatedPagedData>({
      url: `/public/rdb/v1/team-ranking/${sport}-${year}/team-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TransferPortal
 * @name RdbV1TransfersBestAvailableList
 * @summary Gets transfers based on the team, status, year, sport ordered by rating
 * @request GET:/public/rdb/v1/transfers/best-available
 */
export const rdbV1TransfersBestAvailableList = (
  client: AxiosInstance,
  query: RdbV1TransfersBestAvailableListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerTransferRelatedTransferResponseRelatedPagedData>({
      url: `/public/rdb/v1/transfers/best-available`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TransferPortal
 * @name RdbV1TransfersLatestList
 * @summary Gets the latest transfers based on the team, status, year, sport
 * @request GET:/public/rdb/v1/transfers/latest
 */
export const rdbV1TransfersLatestList = (
  client: AxiosInstance,
  query: RdbV1TransfersLatestListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerTransferRelatedTransferResponseRelatedPagedData>({
      url: `/public/rdb/v1/transfers/latest`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TrendingList
 * @name RdbV1TrendingListsList
 * @request GET:/public/rdb/v1/trending-lists
 */
export const rdbV1TrendingListsList = (
  client: AxiosInstance,
  query: RdbV1TrendingListsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TrendingListItemResponseTrendingListResponseRelatedPagedData>({
      url: `/public/rdb/v1/trending-lists`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name RdbV1VideosDetail
 * @summary Gets the videos for the given player key
 * @request GET:/public/rdb/v1/videos/{videoKey}
 */
export const rdbV1VideosDetail = (
  client: AxiosInstance,
  videoKey: number,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponse>({
      url: `/public/rdb/v1/videos/${videoKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name SitesV1EpisodesDetail
 * @request GET:/public/sites/v1/episodes/{key}
 */
export const sitesV1EpisodesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<EpisodeRes>({
      url: `/public/sites/v1/episodes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Episodes
 * @name SitesV1EpisodesList
 * @request GET:/public/sites/v1/episodes
 */
export const sitesV1EpisodesList = (
  client: AxiosInstance,
  query: SitesV1EpisodesListParams,
  params: RequestParams = {},
) =>
  client
    .request<EpisodeResPagedData>({
      url: `/public/sites/v1/episodes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1ExternalShowsList
 * @request GET:/public/sites/v1/external-shows
 */
export const sitesV1ExternalShowsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SimpleShowResPagedData>({
      url: `/public/sites/v1/external-shows`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1NationalShowsList
 * @request GET:/public/sites/v1/national-shows
 */
export const sitesV1NationalShowsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SimpleShowResPagedData>({
      url: `/public/sites/v1/national-shows`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1ShowsDetail
 * @request GET:/public/sites/v1/shows/{key}
 */
export const sitesV1ShowsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ShowRes>({
      url: `/public/sites/v1/shows/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1SitesExternalShowsDetail
 * @request GET:/public/sites/v1/sites/{siteKey}/external-shows
 */
export const sitesV1SitesExternalShowsDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SimpleShowResPagedData>({
      url: `/public/sites/v1/sites/${siteKey}/external-shows`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1SiteShowSummaryList
 * @request GET:/public/sites/v1/site-show-summary
 */
export const sitesV1SiteShowSummaryList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SiteShowSummaryResPagedData>({
      url: `/public/sites/v1/site-show-summary`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Shows
 * @name SitesV1SitesShowsDetail
 * @request GET:/public/sites/v1/sites/{siteKey}/shows
 */
export const sitesV1SitesShowsDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SimpleShowResPagedData>({
      url: `/public/sites/v1/sites/${siteKey}/shows`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsList
 * @summary Public: Gets all NIL Deals
 * @request GET:/public/v1/deals
 */
export const v1DealsList = (
  client: AxiosInstance,
  query: V1DealsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicPlayerDealResponsePagedData>({
      url: `/public/v1/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1OrganizationsDealsDetail
 * @summary Show all commits and rostered players for a specific team that have deals.
 * @request GET:/public/v1/organizations/{organizationKey}/deals
 */
export const v1OrganizationsDealsDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: V1OrganizationsDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicPlayerDealResponsePagedData>({
      url: `/public/v1/organizations/${organizationKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1PeopleDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/public/v1/people/{personKey}/deals
 */
export const v1PeopleDealsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicPlayerDealResponsePagedData>({
      url: `/public/v1/people/${personKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1PlayersDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/public/v1/players/{personKey}/deals
 */
export const v1PlayersDealsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PlayersDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PublicPlayerDealResponsePagedData>({
      url: `/public/v1/players/${personKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
